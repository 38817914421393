import React, { useState, useEffect } from "react";
import axios from 'axios';
import BASE_API_URL from "../../constants/apiConstants";
import { InputDocumentPageHeader, InputDocumentPageTitle, InputDocumentsPageContainer } from "../InputDocumentsPage/InputDocumentsPage.styled";
import BasicTable from "../../components/Table/Table";
import outputDocumentsTableConstants from "../../constants/tableConstants/outputDocumentsTableConstants";
import PrimaryButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const OutputDocumentsPage = () => {
    const [data, setData] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_API_URL}/outputdocuments`).then((res) => {
            console.log(res);
            setData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
    }, [isDataChanged])

    const handleClick = () => {
        navigate('/outputdocuments/new');
    }

    const onDeletedSuccess = (value) => {
        setIsDataChanged(value);
    }

    console.log(isDataChanged);

    return (
        <InputDocumentsPageContainer>
            <InputDocumentPageHeader>
                <InputDocumentPageTitle variant="h4">Izlazni dokumenti</InputDocumentPageTitle>
                <PrimaryButton text={'Dodaj novi'} onClick={handleClick} save />
            </InputDocumentPageHeader>
            {!isLoaded ? <Loader /> : <BasicTable data={data} tableHeader={outputDocumentsTableConstants} tableBody={outputDocumentsTableConstants} onDeletedSuccess={(value) => onDeletedSuccess(value)} type='outputDocuments'/>}
        </InputDocumentsPageContainer>
    );
};

export default OutputDocumentsPage;