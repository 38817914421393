import { Box } from "@mui/material";
import styled from "styled-components";

export const ActionRendererContainer = styled(Box)`
    display: flex;
    gap: 10px;
    justify-content: end;
`;

export const IconContainer = styled(Box)`
    cursor: pointer;
`;