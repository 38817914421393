import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DropdownField(props) {
  const [parsedValue, setParsedValue] = useState([]);
//   const [value, setValue] = React.useState('');

//   const handleChange = (event) => {
//     setValue(event.target.value);
//   };
console.log(props, parsedValue)

useEffect(() => {
    if(typeof(props.items) === 'object') {
      setParsedValue(props.items);
      return;
    }
    try {
      const parsed = JSON.parse(props.items);
      setParsedValue(parsed);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }

}, [props.items]);

  return (
    <Box sx={{ minWidth: 200, margin: '20px 0 20px 0' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={props.id}
          value={props.value}
          label={props.label}
          onChange={props.onChange}
          onInput={props.onInput} ref={props.ref}
          onBlur={props.onBlur} 
          name={props.name}
          error={props.error}
          helperText={props.helperText}
        >
            {parsedValue.map((item, index) => <MenuItem key={index} value={item.title || item.value}>{item.title || item.value}</MenuItem>)}

        </Select>
        {props.error && <Box sx={{color: '#d32f2f', 
                                  fontSize: '0.75rem', 
                                  margin: '3px 14px 0 14px',
                                  lineHeight: '1.66',
                                  fontWeight: '500',
                                  }}>Polje je obavezno!</Box>}
      </FormControl>
    </Box>
  );
}