import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxField(props) {
  return (
      <FormControlLabel control={<Checkbox checked={props.value === true ? true : false} />} 
                        label={props.label} onChange={props.onChange} 
                        value={props.value} 
                        id={props.id} 
                        name={props.name}                    />
  );
}