import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_API_URL from "../../constants/apiConstants";
import { StorageCheckPageContainer, StorageCheckPageTitle } from "./StorageCheckPage.styled";
import DropdownField from "../../components/InputFields/DropdownFiled";
import { NewInputDocumentPageActionContainer, ProductInfoContainer, ProductItemContainer } from "../InputDocumentsPage/NewInputDocumentPage/NewInputDocumentPage.styled";
import InputField from "../../components/InputFields/InputField";

const StorageCheckPage = () => {
    const [productsData, setProductsData] = useState([]);
    const [storagesData, setStoragesData] = useState([]);
    const [inputDocumentsData, setInputDocumentsData] = useState({});
    const [foreignInputDocumentsData, setForeignInputDocumentsData] = useState({});
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        axios.get(`${BASE_API_URL}/storages`).then((res) => {
            console.log(res);
            setStoragesData(res.data);
        }).catch((error) => {
            console.log(error);
        })
        axios.get(`${BASE_API_URL}/products`).then((res) => {
            console.log(res);
            setProductsData(res.data);
        }).catch((error) => {
            console.log(error);
        })
        axios.get(`${BASE_API_URL}/inputdocuments`).then((res) => {
            console.log(res);
            setInputDocumentsData(res.data);
        }).catch((error) => {
            console.log(error);
        })
        axios.get(`${BASE_API_URL}/foreigninputdocuments`).then((res) => {
            console.log(res);
            setForeignInputDocumentsData(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const zajedno = [
        ...inputDocumentsData, ...foreignInputDocumentsData
    ]

    console.log(zajedno.sort((a, b) => new Date(a.createdAt)  - new Date(b.createdAt)));



    return (
        <StorageCheckPageContainer>
            <StorageCheckPageTitle variant="h4">Pregled magacina</StorageCheckPageTitle>
            
            <DropdownField label='Izaberi magacin' items={storagesData} 
                                        id="storage"
                                        name="storage"          
                                        // onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        // value={formik.values.storage} 
                                        // error={formik.touched.storage && formik.errors.storage}
                                        // helperText={formik.touched.storage && formik.errors.storage}
                               />

            <NewInputDocumentPageActionContainer>
                <InputField noMarginTop label='Pretrazi proizvode...' type='text' onChange={(e) => setSearchValue(e.target.value)} />
            </NewInputDocumentPageActionContainer>                   

            {productsData && productsData.filter((item) => String(item.productId).toLowerCase().includes(searchValue.toLowerCase()) || item.title.toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => {
                      const productId = item.productId;
                    //   const isCollapsed = collapsedItems[productId];
                    //   console.log(isCollapsed)

                    return (
                        <>
                            <ProductItemContainer key={index}>
                                <ProductInfoContainer>{item.productId}</ProductInfoContainer>
                                <ProductInfoContainer>{item.title}</ProductInfoContainer>
                            </ProductItemContainer>
                        </>

                    )
                })}                   
        </StorageCheckPageContainer>
    )
};

export default StorageCheckPage;