import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_API_URL from "../../../constants/apiConstants";
import { ProductPageTitle, ProductsPageContainer } from "../ProductsPage.styled";
import InputField from "../../../components/InputFields/InputField";
import { ButtonContainer, NewProductForm, NewProductFormContainer, NewProductFormDiv } from "../NewProductPage/NewProductPage.styled";
import PrimaryButton from "../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import CheckboxField from "../../../components/InputFields/CheckboxField";
import TextAreaField from "../../../components/InputFields/TextAreaField";
import DropdownField from "../../../components/InputFields/DropdownFiled";
import { useFormik } from 'formik';
import newProductInitialValues from "../../../initialValues/newProductInitialValues";
import createProductValidation from "../../../validations/createProductValidation";
import ImagePicker from "../../../components/ImagePicker/ImagePicker";

const EditProductPage = () => {
    const [category, setCategory] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [image, setImage] = useState();
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        const fetchListing = async () => {
          const res = await fetch(`${BASE_API_URL}/products/${id}`);
          const data = await res.json();
          if (data.success === false) {
            console.log(data.message);
            return;
          }

            setCategory(data);
            setImage(data.image);
        };
    
        fetchListing();
      }, [id]);

      useEffect(() => {
        axios.get(`${BASE_API_URL}/categories`).then((res) => {
            console.log(res);
            setCategories(res.data);
        }).catch((error) => {
            console.log(error);
        })
    },[])

 

    const handleSubmit = (values) => {
        const data = {
            productId: values.productId,
            isActive: values.isActive,
            title: values.title,
            category: values.category,
            subcategory: values.subcategory,
            image: image,
            transPackAmount: values.transPackAmount,
            transPackUnit: values.transPackUnit,
            basePackAmount: values.basePackAmount,
            transportMass: values.transportMass,
            barcode: values.barcode,
            tax: values.tax,
            price: values.price,
            description: values.description,
            color: values.color,
        }

        axios
        .put(`${BASE_API_URL}/products/${id}`, data)
        .then(() => {
            navigate('/products');
        })
        .catch((error) => {
            console.log(error);
        })

    }

    console.log(categories, subcategories)
    console.log(image);

    const formik = useFormik({
        initialValues: newProductInitialValues(category),
        validationSchema: createProductValidation,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if(formik.values.category) {
            const subcategories = categories.filter((item) => item.title === formik.values.category)[0].subcategories;
            console.log(subcategories);
            setSubcategories(subcategories);
        }
    }, [formik]);   

    const onImageChange = (img) => {
        setImage(img);
    }


    const handleCancelButton = () => {
        navigate(-1);
    };

        return (
            <ProductsPageContainer component="form" onSubmit={formik.handleSubmit}>
               <ProductPageTitle variant="h4">Izmeni proizvod</ProductPageTitle>
               <NewProductForm>
                    <NewProductFormContainer>
                    <NewProductFormDiv>
                        <InputField label='Sifra proizvoda' type='number' 
                                        id="productId"
                                        name="productId"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.productId} 
                                        error={formik.touched.productId && formik.errors.productId}
                                        helperText={formik.touched.productId && formik.errors.productId}
                                    />
                        <CheckboxField label='Aktivan'
                                       value={formik.values.isActive} 
                                       onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                       onBlur={formik.handleBlur}
                                       id='isActive'
                                       name='isActive' 
                                       error={formik.touched.isActive && formik.errors.isActive}
                                       helperText={formik.touched.isActive && formik.errors.isActive}
                                        /> 
                        <InputField label='Naziv proizvoda' type='text' 
                                        id="title"
                                        name="title"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title} 
                                        error={formik.touched.title && formik.errors.title}
                                        helperText={formik.touched.title && formik.errors.title}
                                    />
                        <DropdownField label='Izaberi kategoriju' items={JSON.stringify(categories)} 
                                        id="category"
                                        name="category"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.category} 
                                        error={formik.touched.category && formik.errors.category}
                                        helperText={formik.touched.category && formik.errors.category}
                                        />
                        <DropdownField label='Izaberi podkategoriju' items={subcategories} 
                                        id="subcategory"
                                        name="subcategory"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.subcategory} 
                                        error={formik.touched.subcategory && formik.errors.subcategory}
                                        helperText={formik.touched.subcategory && formik.errors.subcategory}
                                       />
                        <ImagePicker onImageChange={onImageChange} initialImage={image} />
                    </NewProductFormDiv>
                    <NewProductFormDiv>
                        <InputField label='Kol. trans. pakovanja' type='number' 
                                        id="transPackAmount"
                                        name="transPackAmount"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.transPackAmount} 
                                        error={formik.touched.transPackAmount && formik.errors.transPackAmount}
                                        helperText={formik.touched.transPackAmount && formik.errors.transPackAmount}
                                    />
                        <InputField label='J.M. trans. pakovanja' type='text' 
                                        id="transPackUnit"
                                        name="transPackUnit"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.transPackUnit} 
                                        error={formik.touched.transPackUnit && formik.errors.transPackUnit}
                                        helperText={formik.touched.transPackUnit && formik.errors.transPackUnit}
                                    />
                        <InputField label='Kolicina osnovnog pakovanja' type='number' 
                                        id="basePackAmount"
                                        name="basePackAmount"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.basePackAmount} 
                                        error={formik.touched.basePackAmount && formik.errors.basePackAmount}
                                        helperText={formik.touched.basePackAmount && formik.errors.basePackAmount}
                                    />
                        <InputField label='Transportna masa' type='number' 
                                        id="transportMass"
                                        name="transportMass"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.transportMass} 
                                        error={formik.touched.transportMass && formik.errors.transportMass}
                                        helperText={formik.touched.transportMass && formik.errors.transportMass}
                                    />
                        <InputField label='Barkod' type='number' 
                                        id="barcode"
                                        name="barcode"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.barcode} 
                                        error={formik.touched.barcode && formik.errors.barcode}
                                        helperText={formik.touched.barcode && formik.errors.barcode}
                                    />
                        <InputField label='Stopa PDV u %' type='number' 
                                        id="tax"
                                        name="tax"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tax} 
                                        error={formik.touched.tax && formik.errors.tax}
                                        helperText={formik.touched.tax && formik.errors.tax}
                                    />
                        <InputField label='Cena' type='number' 
                                        id="price"
                                        name="price"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.price} 
                                        error={formik.touched.price && formik.errors.price}
                                        helperText={formik.touched.price && formik.errors.price}
                                    />
                        <TextAreaField placeholder='Opis' 
                                       value={formik.values.description} 
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='description' name='description' 
                                        error={formik.touched.description && formik.errors.description}
                                        helperText={formik.touched.description && formik.errors.description}
                                       />
                        <InputField label='Boja' type='text' 
                                        id="color"
                                        name="color"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.color} 
                                        error={formik.touched.color && formik.errors.color}
                                        helperText={formik.touched.color && formik.errors.color}
                                    />
                    </NewProductFormDiv>
                    </NewProductFormContainer>
                    <ButtonContainer>
                        <PrimaryButton text='Sacuvaj' save type='submit' />
                        <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                    </ButtonContainer>
               </NewProductForm>
    
    
            </ProductsPageContainer>
        ) 
    

};

export default EditProductPage;