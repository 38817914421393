import * as Yup from "yup";

export default () => Yup.object({
    title: Yup.string().required('Naziv kompanije je obavezan!'),
    isActive: Yup.bool(),
    isOnSEF: Yup.bool(),
    idNumber: Yup.string().required('Maticni broj je obavezan!').min(8, 'Maticni broj mora imati 8 cifara!').max(8, 'Maticni broj mora imati 8 cifara!'),
    pib: Yup.string().required('PIB je obavezan!').min(9, 'PIB mora imati 9 cifara').max(9, 'PIB mora imati 9 cifara'),
    address: Yup.string().required('Adresa je obavezna!'),
    city: Yup.string().required('Grad je obavezan!'),
    phone: Yup.string().required('Telefon je obavezan!'),
    phoneMob: Yup.string().required('Mobilni telefon je obavezan!'),
    fax: Yup.string().required('Fax je obavezan!'),
    email: Yup.string().email('Email format nije validan!').required('Email je obavezan!'),
    description: Yup.string(),
  });