import styled from "styled-components";
import { Box } from "@mui/material";

export const CollapsibleFieldContainer = styled(Box)`
    @media (min-width: 768px) {
        margin-bottom: 30px;
    }
`;

export const CommissionConditions = styled(Box)`
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 35px;
`;

export const RetailPriceContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
`;

export const Button = styled.button``;