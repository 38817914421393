import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const NewProductForm = styled(Box)``;

export const NewProductFormContainer = styled(Box)`
    display: flex;
    gap: 50px;
`;

export const NewProductFormDiv = styled(Box)`
`;

export const ButtonContainer = styled(Box)`
    display: flex;
    gap: 20px;
    margin: 20px 0 0 175px;
`;

export const InputFileLabel = styled(Typography)``;

export const InputFile = styled.input`
    width: 100px;
    height: 100px;
`;