import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProductActionRenderer from '../ActionRenderers/ProductsActionRenderer/ProductActionRenderer';
import DeleteProductModal from '../Modals/DeleteProductModal/DeleteProductModal';
import StorageActionRenderer from '../ActionRenderers/StoragesActionRenderer/StoragesActionRenderer';
import DeleteStorageModal from '../Modals/DeleteStorageModal/DeleteStorageModal';
import CompanyActionRenderer from '../ActionRenderers/CompanyActionRenderer/CompanyActionRenderer';
import DeleteCompanyModal from '../Modals/DeleteCompanyModal/DeleteCompanyModal';
import InputDocumentActionRenderer from '../ActionRenderers/InputDocumentActionRenderer/InputDocumentActionRenderer';
import { formattedDate } from '../../util/helpers/dateHelpers';
import ForeignInputDocumentActionRenderer from '../ActionRenderers/ForeignInputDocumentActionRenderer/ForeignInputDocumentActionRenderer';
import CategoryActionRenderer from '../ActionRenderers/CategoryActionRenderer/CategoryActionRenderer';
import { ReactComponent as ActiveIcon } from '../../assets/active.svg';
import OutputDocumentActionRenderer from '../ActionRenderers/OutputDocumentActionRenderer/OutputDocumentActionRenderer';

export default function BasicTable(props) {
  const [openModal, setOpenModal] = useState(false);
  const [returnedId, setReturnedId] = useState('');
  const onOpenModal = (value) => {
    setOpenModal(value);
  }
  const onReturnId = (value) => {
    setReturnedId(value);
  }
  const onDeletedSuccess = (value) => {
    props.onDeletedSuccess(value);
  }
  console.log(props, returnedId)
  console.log(props.tableBody)
  
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.tableHeader.map((item) => (<TableCell key={item.id}>{item.columnTitle}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data?.map((row, index) => (
              <TableRow
                key={row.id || index}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >

                {props.tableBody.map((item) => (<TableCell key={item.id}>{item.body === 'createdAt' ? formattedDate(row[item.body]) : item.body === 'isActive' && row[item.body] ? <ActiveIcon /> : row[item.body]}</TableCell>))}

                {props.type === 'products' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <ProductActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} />
                </TableCell>}
                {props.type === 'storages' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <StorageActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} />
                </TableCell>}
                {props.type === 'companies' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <CompanyActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} />
                </TableCell>}
                {props.type === 'inputDocuments' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <InputDocumentActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} status={row.status} />
                </TableCell>}
                {props.type === 'foreignInputDocuments' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <ForeignInputDocumentActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} status={row.status} />
                </TableCell>}
                {props.type === 'outputDocuments' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <OutputDocumentActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} status={row.status} />
                </TableCell>}
                {props.type === 'categories' && <TableCell sx={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                  <CategoryActionRenderer id={row.id} onOpenModal={onOpenModal} onReturnId={onReturnId} />
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openModal && props.type === 'products' && <DeleteProductModal 
                      id={returnedId} 
                      openModal={openModal} 
                      setOpenModal={setOpenModal}  
                      onOpenModal={(value) => onOpenModal(value)} 
                      onDeletedSuccess={(value) => onDeletedSuccess(value)} />
      }
      {openModal && props.type === 'storages' && <DeleteStorageModal 
                      id={returnedId} 
                      openModal={openModal} 
                      setOpenModal={setOpenModal}  
                      onOpenModal={(value) => onOpenModal(value)} 
                      onDeletedSuccess={(value) => onDeletedSuccess(value)} />
      }
      {openModal && props.type === 'companies' && <DeleteCompanyModal 
                      id={returnedId} 
                      openModal={openModal} 
                      setOpenModal={setOpenModal}  
                      onOpenModal={(value) => onOpenModal(value)} 
                      onDeletedSuccess={(value) => onDeletedSuccess(value)} />
      }
    </>
  );
}