import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_API_URL from "../../../constants/apiConstants";
import InputField from "../../../components/InputFields/InputField";
import PrimaryButton from "../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import DynamicInputFields from "../../../components/InputFields/DynamicInputFields/DynamicInputFields";
import { StoragePageTitle, StoragesPageContainer } from "../../Storages/StoragesPage.styled";
import { ButtonContainer, NewStorageForm, NewStorageFormContainer, NewStorageFormDiv } from "../../Storages/NewStoragePage/NewStoragePage.styled";
import { useFormik } from 'formik';
import createProductCategoryValidation from "../../../validations/createProductCategoryValidation";
import newProductCategoryInitialValues from "../../../initialValues/newProductCategoryInitialValues";

const EditCategoryPage = () => {
    const [category, setCategory] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    console.log(subcategories);

    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        const fetchStorage = async () => {
          const res = await fetch(`${BASE_API_URL}/categories/${id}`);
          const data = await res.json();
          if (data.success === false) {
            console.log(data.message);
            return;
          }

          setCategory(data);
          setSubcategories(data.subcategories);

        };
    
        fetchStorage();
      }, []); 

    const handleSubmit = (values) => {
        const data = {
            title: values.title,
            subcategories: subcategories,
        }
        console.log(data);
        axios
        .put(`${BASE_API_URL}/categories/${id}`, data)
        .then(() => {
            navigate('/categories');
        })
        .catch((error) => {
            console.log(error);
        })

    }

    const formik = useFormik({
        initialValues: newProductCategoryInitialValues(category),
        validationSchema: createProductCategoryValidation,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });


    const handleCancelButton = () => {
        navigate(-1);
    };

        return (
            <StoragesPageContainer component="form" onSubmit={formik.handleSubmit}>
               <StoragePageTitle variant="h4">Izmeni kategoriju</StoragePageTitle>
               <NewStorageForm>
                    <NewStorageFormContainer>
                        <NewStorageFormDiv>
                            <InputField label='Naziv kategorije' type='text' 
                                    id="title"
                                    name="title"          
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title} 
                                    error={formik.touched.title && formik.errors.title}
                                    helperText={formik.touched.title && formik.errors.title}  />
                            <DynamicInputFields label='Podkategorije' existingData={subcategories} onSetInputFields={setSubcategories} onSetParsedArray={setSubcategories} />
                        </NewStorageFormDiv>
                    </NewStorageFormContainer>
                    <ButtonContainer>
                        <PrimaryButton text='Sacuvaj' save type='submit' />
                        <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                    </ButtonContainer>
               </NewStorageForm>
    
    
            </StoragesPageContainer>
        ) 
    

};

export default EditCategoryPage;