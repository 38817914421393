import React, {useState, useEffect} from "react";
import axios from 'axios';
import BASE_API_URL from "../../constants/apiConstants";
import { ProductPageHeader, ProductPageTitle, ProductsPageContainer } from "./ProductsPage.styled";
import BasicTable from "../../components/Table/Table";
import productsTableConstants from "../../constants/tableConstants/productsTableConstants";
import PrimaryButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import useIsTablet from "../../hooks/useIsTablet";

const ProductsPage = () => {
    const [data, setData] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const {isTablet} = useIsTablet();

    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_API_URL}/products`).then((res) => {
            console.log(res);
            setData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
    }, [isDataChanged])

    const handleClick = () => {
        navigate('/products/new');
    }

    const onDeletedSuccess = (value) => {
        setIsDataChanged(value);
    }

    console.log(isDataChanged);

    return (
        <ProductsPageContainer>
            <ProductPageHeader>
                <ProductPageTitle variant={!isTablet ? 'h4' : 'h5' }>Proizvodi</ProductPageTitle>
                <PrimaryButton text={'Dodaj novi'} onClick={handleClick} save />
            </ProductPageHeader>
            {!isLoaded ? <Loader /> : (
                    <BasicTable data={data} tableHeader={productsTableConstants} 
                    tableBody={productsTableConstants} 
                    onDeletedSuccess={(value) => onDeletedSuccess(value)} 
                    type='products' />
            )}        
        </ProductsPageContainer>
    );
};

export default ProductsPage;