import React from "react";
// import BasicMenu from '../../components/MenuComponent/MenuComponent';
import { HomePageContainer } from "./HomePage.styled";

const HomePage = () => {
    return (
        <HomePageContainer>
            {/* <BasicMenu /> */}
        </HomePageContainer>
    );
};

export default HomePage;