import { Box } from "@mui/material";
import styled from "styled-components";

export const NewCompanyForm = styled(Box)``;

export const NewCompanyFormContainer = styled(Box)`
    display: flex;
    gap: 50px;
`;

export const NewCompanyFormDiv = styled(Box)``;

export const ButtonContainer = styled(Box)`
    display: flex;
    gap: 20px;
    margin: 20px 0 0 175px;
`;