// import ProductActionRenderer from "../../components/ActionRenderers/ProductsActionRenderer/ProductActionRenderer";

export default [
    {
       id: 0,
       body: `productId`,
       columnTitle: 'Sifra proizvoda'
   },
    {
       id: 1,
       body: `title`,
       columnTitle:  'Naziv proizvoda'
   },
    {
       id: 2,
       body: `quantity`,
       columnTitle: 'Kolicina'
   },
   {
        id: 3,
        body: `basePrice`,
        columnTitle: 'VP cena'
    },
    {
        id: 4,
        body: `rabat`,
        columnTitle: 'Rabat u %'
    },
    {
        id: 5,
        body: `netoPrice`,
        columnTitle: 'Neto cena'
    },
    {
        id: 6,
        body: `priceWithTax`,
        columnTitle: 'Cena sa PDV-om'
    },
   // {
   //     id: 12,
   //     body: <ProductActionRenderer />,
   //     columnTitle: 'Izmeni'
   // },
]