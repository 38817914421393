import React, { useState, useEffect } from "react";
import { FileInput, FileInputLabel, ImagePickerContainer, SelectedImage, SelectedImageContainer, DeleteButton, DeleteButtonContainer } from "./ImagePicker.styled";

const ImagePicker = ({ initialImage, onImageChange }) => {
  const [selectedImage, setSelectedImage] = useState(initialImage || null);

  useEffect(() => {
    if(initialImage) {
      setSelectedImage(initialImage);
    }
  },[initialImage]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setSelectedImage(event.target.result);
        onImageChange(event.target.result); // Callback to inform parent component
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
    onImageChange(null); // Callback to inform parent component
  };

  return (
    <ImagePickerContainer>
      <FileInput
        type="file"
        id="imagePicker"
        accept="image/*"
        onChange={handleImageChange}
      />
      <FileInputLabel htmlFor="imagePicker">
         Izaberi sliku
      </FileInputLabel>
      <SelectedImageContainer showImage={selectedImage}>
        {selectedImage && <SelectedImage src={selectedImage} alt="Selected Image" />}
        {selectedImage && (
          <DeleteButtonContainer onClick={handleDeleteImage}>
            Ukloni
          </DeleteButtonContainer>
        )}
      </SelectedImageContainer>
    </ImagePickerContainer>
  );
};

export default ImagePicker;