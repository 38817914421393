import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function InputField(props) {
  const { noMarginTop } = props;
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { mt: noMarginTop ? 0 : 1.5 },
      }}
      noValidate
      autoComplete="off"
    >
        <TextField id="outlined-basic" 
                  label={props.label} 
                  variant="outlined" 
                  type={props.type} 
                  onChange={props.onChange} 
                  value={props.value} 
                  inputProps={props.inputProps} 
                  onInput={props.onInput} ref={props.ref}
                  onBlur={props.onBlur} name={props.name}
                  error={props.error}
                  helperText={props.helperText}
                  placeholder={props.placeholder}
                  defaultValue={props.defaultValue}
                  InputLabelProps={props.inputLabelProps}
                  />
                  
                  
    </Box>
  );
}