import styled from 'styled-components';
import { Box, Typography } from "@mui/material";

export const ForeignInputDocumentsPageContainer = styled(Box)`
    padding: 20px;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

export const ForeignInputDocumentPageTitle = styled(Typography)`
   
`;

export const ForeignInputDocumentPageHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;