import styled from "styled-components";

export const ImagePickerContainer = styled.div`
  position: relative;
  width: 220px;
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  cursor: pointer;
  position: relative;
`;

export const PlusSymbol = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  margin-right: 5px; /* Adjust as needed */
`;

export const SelectedImageContainer = styled.div`
  margin-top: 10px;
  display: ${(props) => (props.showImage ? "block" : "none")};
`;

export const SelectedImage = styled.img`
  width: 100%;
  height: auto;
`;

export const DeleteButtonContainer = styled.div`
position: absolute;
top: 5px;
right: 5px;
background-color: red;
color: white;
padding: 5px;
cursor: pointer;
`;

export const DeleteButton = styled.button`
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
`;