import * as Yup from "yup";

export default () => Yup.object({
    productId: Yup.string().required('Sifra proizvoda je obavezna!'),
    title: Yup.string().required('Naziv proizvoda je obavezan!'),
    isActive: Yup.bool(),
    category: Yup.string().required('Kategorija je obavezna!'),
    subcategory: Yup.string().required('Podkategorija je obavezna!'),
    transPackAmount: Yup.string().required('Kol. trans. pakovanja je obavezna!'),
    transPackUnit: Yup.string().required('J.M. trans. pakovanja je obavezna!'),
    basePackAmount: Yup.string().required('Kol. osn. pakovanja je obavezna!'),
    transportMass: Yup.string().required('Transportna masa je obavezna!'),
    barcode: Yup.string().required('Barkod je obavezan!'),
    tax: Yup.string().required('Stopa PDV-a je obavezna!'),
    price: Yup.string().required('Cena je obavezna!'),
    description: Yup.string(),
    color: Yup.string(),
  });