import React, {useState} from "react";
import axios from "axios";
import BASE_API_URL from "../../../constants/apiConstants";
import { ProductPageTitle, ProductsPageContainer } from "../../Products/ProductsPage.styled";
import InputField from "../../../components/InputFields/InputField";
import { ButtonContainer, NewCompanyForm, NewCompanyFormContainer, NewCompanyFormDiv } from "./NewCompanyPage.styled";
import PrimaryButton from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import CheckboxField from "../../../components/InputFields/CheckboxField";
import TextAreaField from "../../../components/InputFields/TextAreaField";
import DynamicInputFields from "../../../components/InputFields/DynamicInputFields/DynamicInputFields";
import { useFormik } from 'formik';
import newCompanyInitialValues from "../../../initialValues/newCompanyInitialValues";
import createCompnayValidation from "../../../validations/createCompnayValidation";
import { toast } from 'react-toastify';

const NewCompanyPage = () => {
    const [unloadPlaces, setUnloadPlaces] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = (values) => {
        const data = {
            title: values.title,
            isActive: values.isActive,
            isOnSEF: values.isOnSEF,
            idNumber: values.idNumber,
            pib: values.pib,
            address: values.address,
            city: values.city,
            phone: values.phone,
            phoneMob: values.phoneMob,
            fax: values.fax,
            email: values.email,
            description: values.description,
            unloadPlaces,
        }

        axios
        .post(`${BASE_API_URL}/companies`, data)
        .then(() => {
            navigate('/companies');
        })
        .catch((error) => {
            console.log(error);
            if(error.response.data === 'idNumber already exists') {
                toast.error('Firma sa unetim maticnim brojem vec postoji!');
            }
            if(error.response.data === 'PIB already exists') {
                toast.error('Firma sa unetim PIB-om vec postoji!');
            }
            if(error.response.data === 'email already exists') {
                toast.error('Firma sa unetim email-om vec postoji!');
            }
        })

    }

    const formik = useFormik({
        initialValues: newCompanyInitialValues(),
        validationSchema: createCompnayValidation,
        onSubmit: handleSubmit,
    });

    const onSetInputFields =(value) => {
        setUnloadPlaces(value);
    }

    const onSetParsedArray =(value) => {
        setUnloadPlaces(value);
    }

    const handleCancelButton = () => {
        navigate(-1);
    };
    return (
        <ProductsPageContainer component="form" onSubmit={formik.handleSubmit}>
           <ProductPageTitle variant="h4">Nova firma</ProductPageTitle>
           <NewCompanyForm>
                <NewCompanyFormContainer>
                    <NewCompanyFormDiv>
                        <InputField label='Naziv firme' type='text' 
                                        id="title"
                                        name="title"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title} 
                                        error={formik.touched.title && formik.errors.title}
                                        helperText={formik.touched.title && formik.errors.title}
                                     />
                        <CheckboxField label='Aktivan' 
                                       value={formik.values.isActive} 
                                       onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                       onBlur={formik.handleBlur}
                                       id='isActive'
                                       name='isActive' 
                                       error={formik.touched.isActive && formik.errors.isActive}
                                       helperText={formik.touched.isActive && formik.errors.isActive}
                                       /> <br />
                        <CheckboxField label='Da li je na SEF-u?' 
                                       value={formik.values.isOnSEF} 
                                       onChange={(e) => formik.setFieldValue('isOnSEF', e.target.checked)}
                                       onBlur={formik.handleBlur}
                                       id='isOnSEF'
                                       name='isOnSEF' 
                                       error={formik.touched.isOnSEF && formik.errors.isOnSEF}
                                       helperText={formik.touched.isOnSEF && formik.errors.isOnSEF}
                                       /> 
                        <InputField label='Maticni broj' type='number' 
                                        id="idNumber"
                                        name="idNumber"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.idNumber} 
                                        error={formik.touched.idNumber && formik.errors.idNumber}
                                        helperText={formik.touched.idNumber && formik.errors.idNumber}
                                    />
                        <InputField label='PIB' type='number' 
                                        id="pib"
                                        name="pib"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.pib} 
                                        error={formik.touched.pib && formik.errors.pib}
                                        helperText={formik.touched.pib && formik.errors.pib}
                                    />
                        <InputField label='Adresa' type='text' 
                                        id="address"
                                        name="address"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.address} 
                                        error={formik.touched.address && formik.errors.address}
                                        helperText={formik.touched.address && formik.errors.address}
                                    />
                        <InputField label='Grad' type='text' 
                                        id="city"
                                        name="city"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city} 
                                        error={formik.touched.city && formik.errors.city}
                                        helperText={formik.touched.city && formik.errors.city}
                                    />
                        <InputField label='Telefon' type='number' 
                                        id="phone"
                                        name="phone"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone} 
                                        error={formik.touched.phone && formik.errors.phone}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                    />
                        <InputField label='Mobilni telefon' type='number' 
                                        id="phoneMob"
                                        name="phoneMob"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phoneMob} 
                                        error={formik.touched.phoneMob && formik.errors.phoneMob}
                                        helperText={formik.touched.phoneMob && formik.errors.phoneMob}
                                    />
                    </NewCompanyFormDiv>
                    <NewCompanyFormDiv>
                        <InputField label='Fax' type='number' 
                                        id="fax"
                                        name="fax"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fax} 
                                        error={formik.touched.fax && formik.errors.fax}
                                        helperText={formik.touched.fax && formik.errors.fax}
                                    />
                        <InputField label='Email' type='text' 
                                        id="email"
                                        name="email"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email} 
                                        error={formik.touched.email && formik.errors.email}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                        <TextAreaField placeholder='Opis' 
                                        id="description"
                                        name="description"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description} 
                                        error={formik.touched.description && formik.errors.description}
                                        helperText={formik.touched.description && formik.errors.description}
                                       />
                        <DynamicInputFields label='Istovarna mesta' existingData={unloadPlaces} 
                                            onSetInputFields={onSetInputFields} 
                                            onSetParsedArray={onSetParsedArray}
                                            placeholder='Istovarno mesto &#10;Adresa &#10;Telefon'
                                            type='textArea'    
                                            />
                    </NewCompanyFormDiv>
                </NewCompanyFormContainer>
                <ButtonContainer>
                    <PrimaryButton text='Sacuvaj' save type='submit' />
                    <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                </ButtonContainer>
           </NewCompanyForm>


        </ProductsPageContainer>
    ) 
};

export default NewCompanyPage;