import React, { useContext, useState } from 'react';
import axios from 'axios';
import BASE_API_URL from '../../constants/apiConstants';
import { LoginContainer, LoginForm } from './LoginPage.styled';
import InputField from '../../components/InputFields/InputField';
import PrimaryButton from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../ctx/AuthContext';

const LoginPage = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {setAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = () => {
        const data = { username, password };
        axios
          .post(`${BASE_API_URL}/auth/login`, data)
          .then((response) => {
            if(response.data.error) {
              console.log(response.data.error);
            } else {
              localStorage.setItem('accessToken', response.data);
              console.log('Login successful:', response.data);
              setAuthenticated(true);
              navigate('/');
            }
          })
          .catch((error) => {
            console.log('Login failed:', error.response.data);
          });
      };
    return (
        <LoginContainer>
            <LoginForm>
                <InputField type='text' label='Korisnicko ime' onChange={(e) => setUsername(e.target.value)} />
                <InputField type='password' label='Lozinka' onChange={(e) => setPassword(e.target.value)} />
                <PrimaryButton text='Uloguj se' onClick={handleLogin} />
            </LoginForm>
        </LoginContainer>
    )
};

export default LoginPage;