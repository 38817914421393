import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { ReactComponent as IconDropdown } from "../../../assets/dropdown-icon.svg";

export const NewInputDocumentContainer = styled(Box)`
    padding: 20px;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

export const NewInputDocumentHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

export const NewInputDocumentTitle = styled(Typography)``;

export const ProductItemContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-bottom: 30px;
    }
`;

export const ProductInfoContainer = styled(Box)`
    min-width: 100px;

    @media (max-width: 768px) {
        min-width: 80px;
    }
`;

export const ProductInfoContainerAlignRight = styled(Box)`
        min-width: 100px;
        display: flex;
        justify-content: end;

`;

export const ButtonContainer = styled(Box)`
    display: flex;
    gap: 20px;
    justify-content: center;
`;

export const NewInputDocumentCommentsContainer = styled(Box)`
    // width: 100%
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
`;

export const DropdownIconContainer = styled(Box)`
    cursor: pointer;
    padding: 0 20px;
`;

export const DropdownIcon = styled(IconDropdown)`
    transform: ${(props) => (props.isCollapsed === false ? 'rotate(180deg)' : '')};
`;

export const NewInputDocumentCollapsibleContainer = styled(Box)``;

export const NewInputDocumentPageActionContainer = styled(Box)`
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    gap: 20px;
`;

export const CourseField = styled(Box)`
    margin-left: auto;
`;