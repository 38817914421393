// import ProductActionRenderer from "../../components/ActionRenderers/ProductsActionRenderer/ProductActionRenderer";

export default [
    {
        id: 1,
        body: `title`,
        columnTitle: 'Naziv firme'
    },
    {
       id: 2,
       body: `isActive`,
       columnTitle:  'Aktivan'
   },
    {
       id: 3,
       body: `idNumber`,
       columnTitle: 'Maticni broj'
   },
    {
       id: 4,
       body: `pib`,
       columnTitle: 'PIB'
   },
    {
       id: 5,
       body: `address`,
       columnTitle: 'Adresa'
   },
    {
       id: 6,
       body: `city`,
       columnTitle: 'Grad'
   },
    {
       id: 7,
       body: `phone`,
       columnTitle: 'Telefon'
   },
    {
       id: 8,
       body: `phoneMob`,
       columnTitle: 'Mobilni telefon'
   },
    {
       id: 9,
       body: `fax`,
       columnTitle: 'fax'
   },
    {
       id: 10,
       body: `email`,
       columnTitle: 'email'
   },
    {
       id: 11,
       body: `description`,
       columnTitle: 'Opis'
   },
   // {
   //     id: 12,
   //     body: <ProductActionRenderer />,
   //     columnTitle: 'Izmeni'
   // },
]