import * as React from 'react';
import Box from '@mui/joy/Box';
import Textarea from '@mui/joy/Textarea';

export default function TextAreaField(props) {
  return (
    <Box sx={{ mt: 1.5 }}>
      <Textarea
        placeholder={props.placeholder}
        // defaultValue="Try to put text longer than 4 lines."
        minRows={3}
        maxRows={5}
        onChange={props.onChange}
        value={props.value}
        id={props.id}
        name={props.name}
        error={props.error}
        helperText={props.helperText} 
      />
    </Box>
  );
}