export default [
    {
       id: 0,
       body: `storage`,
       columnTitle: 'Naziv magacina'
   },
    {
       id: 1,
       body: `company`,
       columnTitle:  'Naziv firme'
   },
    {
       id: 2,
       body: `createdAt`,
       columnTitle: 'Datum'
   },
]