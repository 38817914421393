import { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MiniDrawer from './components/Drawer/Drawer';
import ProductsPage from './pages/Products/ProductsPage';
import HomePage from './pages/Home/HomePage';
import NewProductPage from './pages/Products/NewProductPage/NewProductPage';
import EditProductPage from './pages/Products/EditProductPage/EditProductPage';
import StoragesPage from './pages/Storages/StoragesPage';
import NewStoragePage from './pages/Storages/NewStoragePage/NewStoragePage';
import EditStoragePage from './pages/Storages/EditStoragePage/EditStoragePage';
import CompaniesPage from './pages/Companies/CompaniesPage';
import NewCompanyPage from './pages/Companies/NewCompanyPage/NewCompanyPage';
import EditCompanyPage from './pages/Companies/EditCompanyPage/EditCompanyPage';
import InputDocumentsPage from './pages/InputDocumentsPage/InputDocumentsPage';
import NewInputDocumentPage from './pages/InputDocumentsPage/NewInputDocumentPage/NewInputDocumentPage';
import InputDocumentDetailsPage from './pages/InputDocumentsPage/InputDocumentDetailPage/InputDocumentDetails';
import ForeignInputDocumentsPage from './pages/ForeignInputDocumentsPage/ForeignInputDocumentsPage';
import NewForeignInputDocumentPage from './pages/ForeignInputDocumentsPage/NewForeignInputDocument/NewForeignInputDocumentPage';
import ForeignInputDocumentDetailsPage from './pages/ForeignInputDocumentsPage/ForeignInputDocumentDetailPage/ForeignInputDocumentDetailPage';
import CategoriesPage from './pages/Categories/CategoriesPage';
import NewCategoryPage from './pages/Categories/NewCategoryPage/NewCategoryPage';
import EditCategoryPage from './pages/Categories/EditCategoryPage/EditCategoryPage';
import OutputDocumentsPage from './pages/OutputDocumentsPage/OutputDocumentPage';
import NewOutputDocumentPage from './pages/OutputDocumentsPage/NewOutputDocumentPage/NewOutputDocumentPage';
import LevelingPrices from './pages/LevelingPrices/LevelingPrices';
import StorageCheckPage from './pages/StorageCheck/StorageCheckPage';
import EditInputDocumentPage from './pages/InputDocumentsPage/EditInputDocumentPage/EditInputDocumentPage';
import EditForeignInputDocumentPage from './pages/ForeignInputDocumentsPage/EditForeignInputDocumentPage/EditForeignInputDocumentPage';
import EditOutputDocumentPage from './pages/OutputDocumentsPage/EditOutputDocumentPage/EditOutputDocumentPage';
import OutputDocumentDetailsPage from './pages/OutputDocumentsPage/OutputDocumentDetailsPage/OutputDocumentDetailsPage';
import LoginPage from './pages/LoginPage/LoginPage';
import axios from 'axios';
import { AuthContext } from './ctx/AuthContext';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    axios.get('https://api.ivexkd.rs/auth/auth', {
      headers: {
        accessToken: localStorage.getItem('accessToken'),
      },
    }).then((res) => {
      if(res.data.error) {
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
      }
    })
  }, []);
  return (
    <>
      <MiniDrawer content={
        <>
              <ToastContainer />
              <AuthContext.Provider value={{authenticated, setAuthenticated}}>
              <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/' element={<HomePage />} />
                    {/* {authenticated && (
                      <> */}
                      <Route path='/products' element={<ProductsPage />} />
                    <Route path='/products/new' element={<NewProductPage />} />
                    <Route path='/products/edit/:id' element={<EditProductPage />} />
                    <Route path='/storages' element={<StoragesPage />} />
                    <Route path='/storages/new' element={<NewStoragePage />} />
                    <Route path='/storages/edit/:id' element={<EditStoragePage />} />
                    <Route path='/companies' element={<CompaniesPage />} />
                    <Route path='/companies/new' element={<NewCompanyPage />} />
                    <Route path='/companies/edit/:id' element={<EditCompanyPage />} />
                    <Route path='/inputdocuments' element={<InputDocumentsPage />} />
                    <Route path='/inputdocuments/new' element={<NewInputDocumentPage />} />
                    <Route path='/inputdocuments/:id' element={<InputDocumentDetailsPage />} />
                    <Route path='/inputdocuments/edit/:id' element={<EditInputDocumentPage />} />
                    <Route path='/foreigninputdocuments' element={<ForeignInputDocumentsPage />} />
                    <Route path='/foreigninputdocuments/new' element={<NewForeignInputDocumentPage />} />
                    <Route path='/foreigninputdocuments/:id' element={<ForeignInputDocumentDetailsPage />} />
                    <Route path='/foreigninputdocuments/edit/:id' element={<EditForeignInputDocumentPage />} />
                    <Route path='/categories' element={<CategoriesPage />} />
                    <Route path='/categories/new' element={<NewCategoryPage />} />
                    <Route path='/categories/edit/:id' element={<EditCategoryPage />} />
                    <Route path='/outputdocuments' element={<OutputDocumentsPage />} />
                    <Route path='/outputdocuments/new' element={<NewOutputDocumentPage />} />
                    <Route path='/outputdocuments/edit/:id' element={<EditOutputDocumentPage />} />
                    <Route path='/outputdocuments/:id' element={<OutputDocumentDetailsPage />} />
                    <Route path='/levelingprices' element={<LevelingPrices />} />
                    <Route path='/storagecheck' element={<StorageCheckPage />} />
                      
                      {/* </>
                    ) } */}
                {/* <Route path='' element={} />
                <Route path='' element={} /> */}
              </Routes>
              </AuthContext.Provider>
        </>
      } />
    </>

  );
}


export default App;
