import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_API_URL from "../../../constants/apiConstants";
import { StoragePageTitle, StoragesPageContainer } from "../StoragesPage.styled";
import InputField from "../../../components/InputFields/InputField";
import { ButtonContainer, NewStorageForm, NewStorageFormContainer, NewStorageFormDiv } from "../NewStoragePage/NewStoragePage.styled";
import PrimaryButton from "../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import CheckboxField from "../../../components/InputFields/CheckboxField";
import TextAreaField from "../../../components/InputFields/TextAreaField";
import { useFormik } from 'formik';
import createStorageValidation from "../../../validations/createStorageValidation";
import newStorageInitialValues from "../../../initialValues/newStorageInitialValues";

const EditStoragePage = () => {
    const [storage, setStorage] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        const fetchStorage = async () => {
          const res = await fetch(`${BASE_API_URL}/storages/${id}`);
          const data = await res.json();
          if (data.success === false) {
            console.log(data.message);
            return;
          }
            setStorage(data);
        };
    
        fetchStorage();
      }, [id]); 

    const handleSubmit = (values) => {
        const data = {
            title: values.title,
            isActive: values.isActive,
            description: values.description,
        }

        axios
        .put(`${BASE_API_URL}/storages/${id}`, data)
        .then(() => {
            navigate('/storages');
        })
        .catch((error) => {
            console.log(error);
        })

    }

    const formik = useFormik({
        initialValues: newStorageInitialValues(storage),
        validationSchema: createStorageValidation,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const handleCancelButton = () => {
        navigate(-1);
    };

        return (
            <StoragesPageContainer component="form" onSubmit={formik.handleSubmit}>
               <StoragePageTitle variant="h4">Izmeni magacin</StoragePageTitle>
               <NewStorageForm>
                    <NewStorageFormContainer>
                        <NewStorageFormDiv>
                        <InputField label='Naziv magacina' type='text'           
                                        id="title"
                                        name="title"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title}
                                        error={formik.touched.title && Boolean(formik.errors.title)}
                                        helperText={formik.touched.title && formik.errors.title} />
                        <CheckboxField label='Aktivan' value={formik.values.isActive} 
                                        onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                        onBlur={formik.handleBlur}
                                        id='isActive'
                                        name='isActive' 
                                        error={formik.touched.isActive && Boolean(formik.errors.isActive)}
                                        helperText={formik.touched.isActive && formik.errors.isActive} /> 
                        <TextAreaField placeholder='Opis' value={formik.values.description}                                         
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='description' name='description' 
                                        error={formik.touched.description && Boolean(formik.errors.description)}
                                        helperText={formik.touched.description && formik.errors.description} />
                        </NewStorageFormDiv>
                    </NewStorageFormContainer>
                    <ButtonContainer>
                        <PrimaryButton text='Sacuvaj' save type='submit'/>
                        <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                    </ButtonContainer>
               </NewStorageForm>
    
    
            </StoragesPageContainer>
        ) 
    

};

export default EditStoragePage;