import React, { useState, useEffect } from "react";
import axios from 'axios';
import BASE_API_URL from "../../../constants/apiConstants";
import DropdownField from "../../../components/InputFields/DropdownFiled";
import { NewInputDocumentContainer, NewInputDocumentTitle, ProductItemContainer, ButtonContainer, NewInputDocumentCommentsContainer, DropdownIcon, DropdownIconContainer, ProductInfoContainer, NewInputDocumentPageActionContainer, NewInputDocumentHeader, ProductInfoContainerAlignRight } from "../../InputDocumentsPage/NewInputDocumentPage/NewInputDocumentPage.styled";
import InputField from "../../../components/InputFields/InputField";
import PrimaryButton from '../../../components/Button/Button';
import { useNavigate, useParams } from "react-router-dom";
import TextAreaField from "../../../components/InputFields/TextAreaField";
import CollapsibleFields from "../NewOutputDocumentPage/CollapsibleFields/CollapsibleFields";
import Loader from "../../../components/Loader/Loader";
import { useFormik } from 'formik';
import newOutputDocumentInitialValues from "../../../initialValues/newOutputDocumentInitialValues";
import createOutputDocumentValidation from "../../../validations/createOutputDocumentValidation";
import fetchData from "../../../util/helpers/fetchDataHelpers";
import documentStatusConstants from "../../../constants/documentStatusConstants";

const EditOutputDocumentPage = () => {
    const [inputData, setInputData] = useState({});
    const [productsData, setProductsData] = useState([]);
    const [storagesData, setStoragesData] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const [outputDocumentsData, setOutputDocumentsData] = useState({});
    const [storageChecks, setStorageChecks] = useState({});
    const [vpPrices, setVpPrices] = useState({});
    const [productCategories, setProductCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [collapsedItems, setCollapsedItems] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    console.log(productsData);

    useEffect(() => {
        // Initialize collapsed state for all products to true
        const initialCollapsedState = {};
        productsData.forEach((item) => {
          initialCollapsedState[item.productId] = true;
        });
        setCollapsedItems(initialCollapsedState);
      }, [productsData]);

      useEffect(() => {
        fetchData(`${BASE_API_URL}/outputdocuments/${id}`, setInputData);
        fetchData(`${BASE_API_URL}/products`, setProductsData, setIsLoaded);
        fetchData(`${BASE_API_URL}/storages`, setStoragesData);
        fetchData(`${BASE_API_URL}/companies`, setCompaniesData);
        fetchData(`${BASE_API_URL}/outputdocuments`, setOutputDocumentsData);
        fetchData(`${BASE_API_URL}/storagechecks`, setStorageChecks);
        fetchData(`${BASE_API_URL}/categories`, setProductCategories);
        // fetchData('http://localhost:3001/levelingprices', setVpPrices);
      }, []);

      useEffect(() => {
        if(filterValue) {
            const subcategories = productCategories?.filter((item) => item.title === filterValue)[0]?.subcategories;
            console.log(subcategories);
            setSubcategories(subcategories);
        }
    }, [filterValue]);  

    useEffect(() => {
        const unloadCallback = (event) => {
          event.preventDefault();
          event.returnValue = "";
          return "";
        };
      
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
      }, []);

    const handleSubmit = (values) => {
        const data = {
            company: values.company,
            storage: values.storage,
            status: values.status,
            inputData: values.inputData,
            basicComment: values.basicComment,
            storageComment: values.storageComment,
        }
        console.log(data);
        if(Object.keys(data.inputData).length === 0) {
            console.log('Forma je prazna!');
            return;
        }

        if(data.status === 'U izdradi') {
            axios
            .put(`${BASE_API_URL}/outputdocuments/${id}`, data)
            .then(() => {
                navigate('/outputdocuments');
            })
            .catch((error) => {
                console.log(error);
            })
        }

        if(data.status === 'Zavrsen') {
            const oldData = storageChecks[0]?.inputData;
            const newData = data.inputData;
            Object.values(newData).forEach((item) => {
                if (oldData && oldData[item.productId]) {
                  oldData[item.productId].quantity = parseFloat(oldData[item.productId].quantity) - (parseFloat(item.quantity) || 0);
                  oldData[item.productId].price = oldData[item.productId].price;
                  oldData[item.productId].value = parseFloat(oldData[item.productId].quantity) * oldData[item.productId].price;
                }
            });
    
            const dataToSend = {inputData: oldData};
            console.log(dataToSend);
    
            axios
            .put(`${BASE_API_URL}/storagechecks`, dataToSend)
            .then(() => {
                // navigate('/inputdocuments');
            })
            .catch((error) => {
                console.log(error);
            })
    
            axios
            .put(`${BASE_API_URL}/outputdocuments/${id}`, data)
            .then(() => {
                navigate('/outputdocuments');
            })
            .catch((error) => {
                console.log(error);
            })
        }

    };  

    const formik = useFormik({
        initialValues: newOutputDocumentInitialValues(inputData),
        validationSchema: createOutputDocumentValidation,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const handleInputChange = (productId, title, field, value) => {
        console.log(field);
        const parsedValue = parseFloat(value) || 0;
        const { productId: idProd } = productsData.find((item) => item.id === productId);

        formik.setFieldValue(`inputData.${productId}.${field}`, parsedValue);
        formik.setFieldValue(`inputData.${productId}.productId`, idProd);
        formik.setFieldValue(`inputData.${productId}.title`, title);

        const { price: basePrice } = productsData.find((item) => item.id === productId);
        const rabat = field === 'rabat' ? parsedValue : parseFloat(formik?.values?.inputData[productId]?.rabat) || 0;
        const { tax } = productsData.find((item) => item.id === productId);
        if (field === 'comment') {
            formik.setFieldValue(`inputData.${productId}.comment`, value);
        }

        const netoPrice = basePrice - (rabat / 100) * basePrice;
        const priceWithTax = netoPrice + ((tax / 100) * netoPrice);
    
        // Set the vpPrice field in Formik
        formik.setFieldValue(`inputData.${productId}.basePrice`, basePrice);

        formik.setFieldValue(`inputData.${productId}.netoPrice`, netoPrice);
        formik.setFieldValue(`inputData.${productId}.priceWithTax`, priceWithTax);
        // formik.setFieldValue(`inputData.${productId}.comment`, comment);
    };

    // const calculateNetoPrice = (productId) => {
    //     const basePrice = parseFloat(formik.values.inputData[productId]?.basePrice);
    //     const rabat = parseFloat(formik.values.inputData[productId]?.rabat);
    
    //     const netoPrice = basePrice - ((rabat / 100) * basePrice);
    //     return isNaN(netoPrice) ? '' : netoPrice.toFixed(2);
    //   };

    // const calculatePriceWithPDV = (productId) => {
    //     const basePrice = parseFloat(formik.values.inputData[productId]?.basePrice);
    //     const { tax } = productsData.find((item) => item.id === productId);
    //     const rabat = parseFloat(formik.values.inputData[productId]?.rabat);
    
    //     const netoPrice = basePrice - ((rabat / 100) * basePrice);
        
    //     const priceWithTax = netoPrice + ((tax / 100) * netoPrice);
    //     return isNaN(priceWithTax) ? '' : priceWithTax.toFixed(2);
    // }

    const handleCancelButton = () => {
        if(Object.keys(formik.touched).length > 0) {
            if(window.confirm('Promene koje ste napravili nece biti sacuvane. Da li ste sigurni?')) {
                navigate('/outputdocuments');
            }
        } else {
            navigate('/outputdocuments');
        }
    };  

    return (
        <>
            <NewInputDocumentContainer component="form" onSubmit={formik.handleSubmit}>
                <NewInputDocumentHeader>
                    <NewInputDocumentTitle variant="h4">Izmeni izlazni dokument</NewInputDocumentTitle>
                    <ButtonContainer>
                            <PrimaryButton text='Sacuvaj' save type='submit' />
                            <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                    </ButtonContainer>
                </NewInputDocumentHeader>
                    <DropdownField label='Izaberi magacin' items={storagesData}
                                            id="storage"
                                            name="storage"          
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.storage} 
                                            error={formik.touched.storage && formik.errors.storage}
                                            helperText={formik.touched.storage && formik.errors.storage}
                                />

                    <DropdownField label='Izaberi firmu' items={companiesData} 
                                            id="company"
                                            name="company"          
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.company} 
                                            error={formik.touched.company && formik.errors.company}
                                            helperText={formik.touched.company && formik.errors.company}
                                />
                    <DropdownField label='Izaberi status dokumenta' items={documentStatusConstants} 
                                        id="status"
                                        name="status"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.status} 
                                        error={formik.touched.status && formik.errors.status}
                                        helperText={formik.touched.status && formik.errors.status}
                    />   

                <NewInputDocumentCommentsContainer>
                    <TextAreaField placeholder='Komentar (opsti)' 
                                       value={formik.values.basicComment} 
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='basicComment' name='basicComment' 
                                        error={formik.touched.basicComment && formik.errors.basicComment}
                                        helperText={formik.touched.basicComment && formik.errors.basicComment}
                                   />
                    <TextAreaField placeholder='Komentar (magacin)' 
                                       value={formik.values.storageComment} 
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='storageComment' name='storageComment' 
                                        error={formik.touched.storageComment && formik.errors.storageComment}
                                        helperText={formik.touched.storageComment && formik.errors.storageComment}
                                   />
                </NewInputDocumentCommentsContainer>
                <NewInputDocumentPageActionContainer>
                    <InputField noMarginTop label='Pretrazi proizvode...' type='text' onChange={(e) => setSearchValue(e.target.value)} />
                    <DropdownField label='Filter kategorija' items={productCategories} 
                                        id="category"
                                        name="category"          
                                        onChange={(e) => setFilterValue(e.target.value)}
                                        value={filterValue}
                    /> 
                    <DropdownField label='Filter podkategorija' items={subcategories} 
                                        id="subcategory"
                                        name="subcategory"          
                                        onChange={(e) => setFilterValue(e.target.value)}
                                        value={filterValue}
                    /> 
                    <PrimaryButton text='Ponisti' onClick={() => setFilterValue('')} />
                </NewInputDocumentPageActionContainer>
                {!isLoaded ? <Loader /> : productsData
                                                      .sort((a, b) => a.category.localeCompare(b.category))
                                                      .filter((item) => String(item.productId).toLowerCase().includes(searchValue.toLowerCase()) || item.title.toLowerCase().includes(searchValue.toLowerCase()) || String(item.barcode).toLowerCase().includes(searchValue.toLowerCase()))
                                                      .filter((item) => filterValue === '' || item.category === filterValue || item.subcategory === filterValue)
                                                      .map((item) => {
                      const productId = item.id;
                      const isCollapsed = collapsedItems[productId];
                      console.log(storageChecks);
                      let purchasePrice;
                      if(storageChecks && typeof storageChecks === 'object' && storageChecks[0]?.inputData) {
                        purchasePrice = Object.keys(storageChecks[0]?.inputData).length !== 0 ? storageChecks[0]?.inputData[item.productId]?.price : 0;
                    }

                      const lastRabat = formik.values.company === '' ? 0 : outputDocumentsData?.filter((item) => item.company === formik.values.company);
                      console.log(lastRabat);
                      let numRabat;
                        if(formik.values.company) {
                            lastRabat?.forEach((item) => {
                                console.log('productId', productId, item.inputData[productId]?.rabat);
                                numRabat = item.inputData[productId]?.rabat;
                              })
                        }

                    return (
                        <>
                            <ProductItemContainer key={productId}>
                                <ProductInfoContainer>{item.productId}</ProductInfoContainer>
                                <ProductInfoContainer>{item.title}</ProductInfoContainer>
                                <ProductInfoContainer>{item.barcode}</ProductInfoContainer>
                                <ProductInfoContainerAlignRight>{storageChecks[0]?.inputData && storageChecks[0]?.inputData[item.productId]?.quantity}</ProductInfoContainerAlignRight>
                                    <DropdownIconContainer onClick={() =>
                                                            setCollapsedItems((prevCollapsed) => ({
                                                                ...prevCollapsed,
                                                                [productId]: !prevCollapsed[productId],
                                                            }))
                                                            } 
                                    >
                                    <DropdownIcon
                                        isCollapsed={!isCollapsed}
                                    />
                                    </DropdownIconContainer>
                                <InputField label='Kolicina' type='number' 
                                           name={`inputData.${productId}.quantity`}
                                           onChange={(e) => handleInputChange(item.id, item.title, 'quantity', e.target.value)}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.inputData[productId]?.quantity}
                                />
                                <InputField label='VP cena' type='number' 
                                            name={`inputData.${productId}.basePrice`}
                                            value={formik.values.inputData[productId]?.basePrice}
                                            // onChange={(e) => handleInputChange(item.productId, item.title, 'basePrice', e.target.value)}
                                            inputProps={{ readOnly: true,}} 
                                            inputLabelProps={{
                                                shrink: true,
                                              }}
                                            // onInput={() => formik.setFieldValue(`inputData.${productId}.basePrice`, vpPrices?.inputData[item.productId]?.vpPrice)}
                                            // value={Object.keys(vpPrices).length !== 0 ? vpPrices?.inputData[item.productId]?.vpPrice : 0} 
                                            // onChange={(e) => handleInputChange(item.productId, item.title, 'basePrice', e.target.value)} 
                                />
                                <InputField label='Rabat u %' type='number' 
                                            name={`inputData.${productId}.rabat`}
                                            onChange={(e) => handleInputChange(item.id, item.title, 'rabat', e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.inputData[productId]?.rabat} 
                                />
                                <InputField label='Neto cena' type='number' 
                                            defaultValue={0}
                                            name={`inputData.${productId}.netoPrice`}
                                            inputProps={{ readOnly: true,}} value={formik.values.inputData[productId]?.netoPrice}
                                />
                                <InputField label='Cena sa PDV-om' type='number' 
                                            defaultValue={0}
                                            name={`inputData.${productId}.priceWithTax`}
                                            inputProps={{ readOnly: true,}} value={formik.values.inputData[productId]?.priceWithTax}
                                />
                                <InputField label='Komentar' type='text' 
                                            name={`inputData.${productId}.comment`}
                                            onChange={(e) => handleInputChange(item.id, item.title, 'comment', e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.inputData[productId]?.comment} 
                                />
                            </ProductItemContainer>
                            {isCollapsed && (
                                <CollapsibleFields productId={productId} outputData={outputDocumentsData} company={formik.values.company} isCollapsed={isCollapsed} netoPrice={formik.values.inputData[productId]?.netoPrice} purchasePrice={purchasePrice} priceWithTax={formik.values.inputData[productId]?.priceWithTax} />
                            )}
                        </>

                    )
                })}
            </NewInputDocumentContainer>
        </>
    )
};

export default EditOutputDocumentPage;