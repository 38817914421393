import React, { useState, useEffect } from "react";
import axios from 'axios';
import BASE_API_URL from "../../../constants/apiConstants";
import DropdownField from "../../../components/InputFields/DropdownFiled";
import { NewInputDocumentContainer, NewInputDocumentTitle, ProductItemContainer, ButtonContainer, NewInputDocumentCommentsContainer, DropdownIcon, NewInputDocumentCollapsibleContainer, DropdownIconContainer, ProductInfoContainer, NewInputDocumentHeader } from "../NewForeignInputDocument/NewForeignInputDocumentPage.styled";
import InputField from "../../../components/InputFields/InputField";
import PrimaryButton from '../../../components/Button/Button';
import { useNavigate, useParams } from "react-router-dom";
import TextAreaField from "../../../components/InputFields/TextAreaField";
import { CourseField, NewInputDocumentPageActionContainer } from "../../InputDocumentsPage/NewInputDocumentPage/NewInputDocumentPage.styled";
import Loader from "../../../components/Loader/Loader";
import CollapsibleFields from "../../InputDocumentsPage/NewInputDocumentPage/CollapsibleFields/CollapsibleFields";
// import CollapsibleFields from "./CollapsibleFields/CollapsibleFields";
import { useFormik } from 'formik';
import newForeignInputDocumentInitalValues from '../../../initialValues/newForeignInputDocumentInitialValues';
import createForeignInputDocumentValidation from "../../../validations/createForeignInputDocumentValidation";
import useIsTablet from "../../../hooks/useIsTablet";
import fetchData from "../../../util/helpers/fetchDataHelpers";
import documentStatusConstants from "../../../constants/documentStatusConstants";

const EditForeignInputDocumentPage = () => {
    const [inputData, setInputData] = useState({});
    const [productsData, setProductsData] = useState([]);
    const [storagesData, setStoragesData] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const [rates, setRates] = useState();
    const [inputDocumentsData, setInputDocumentsData] = useState({});
    const [foreignInputDocumentsData, setForeignInputDocumentsData] = useState({});
    const [storageChecks, setStorageChecks] = useState({});
    const [productCategories, setProductCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [collapsedItems, setCollapsedItems] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const { isTablet } = useIsTablet();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        // Initialize collapsed state for all products to true
        const initialCollapsedState = {};
        productsData.forEach((item) => {
          initialCollapsedState[item.productId] = true;
        });
        setCollapsedItems(initialCollapsedState);
      }, [productsData]); 

      useEffect(() => {
        fetchData(`${BASE_API_URL}/foreigninputdocuments/${id}`, setInputData);
        fetchData(`${BASE_API_URL}/products`, setProductsData, setIsLoaded);
        fetchData(`${BASE_API_URL}/storages`, setStoragesData);
        fetchData(`${BASE_API_URL}/companies`, setCompaniesData);
        fetchData(`${BASE_API_URL}/inputdocuments`, setInputDocumentsData);
        fetchData(`${BASE_API_URL}/foreigninputdocuments`, setForeignInputDocumentsData);
        fetchData(`${BASE_API_URL}/storagechecks`, setStorageChecks);
        fetchData(`${BASE_API_URL}/categories`, setProductCategories);
        // fetchData('/api/v1/currencies/eur/rates/today', setRates);
      }, [id]);

      useEffect(() => {
        if(filterValue) {
            const subcategories = productCategories?.filter((item) => item.title === filterValue)[0]?.subcategories;
            console.log(subcategories);
            setSubcategories(subcategories);
        }
    }, [filterValue]);  

    useEffect(() => {
        const unloadCallback = (event) => {
          event.preventDefault();
          event.returnValue = "";
          return "";
        };
      
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
      }, []);

    const handleSubmit = (values) => {
        const data = {
            company: values.company,
            storage: values.storage,
            status: values.status,
            inputData: values.inputData,
            basicComment: values.basicComment,
            storageComment: values.storageComment,
        }
        console.log(data);
        if(Object.keys(data.inputData).length === 0) {
            console.log('Forma je prazna!');
            return;
        }

        if(data.status === 'U izdradi') {
            axios
            .put(`${BASE_API_URL}/foreigninputdocuments/${id}`, data)
            .then(() => {
                navigate('/foreigninputdocuments');
            })
            .catch((error) => {
                console.log(error);
            })
            return;
        }

        if(data.status === 'Zavrsen') {
            const oldData = storageChecks[0].inputData;
            console.log(oldData);
            const newData = data.inputData;
            console.log(newData);
            
            Object.values(newData).forEach((item) => {
                console.log(item);
                if (oldData && oldData[item.productId]) {
                    oldData[item.productId].quantity = (parseFloat(oldData[item.productId].quantity) || 0) + (parseFloat(item.quantity) || 0);
                    oldData[item.productId].value = (parseFloat(oldData[item.productId].value) || 0) + (item.quantity ? parseFloat(item.quantity) * parseFloat(item.netoPrice) : 0);
                    oldData[item.productId].price = oldData[item.productId].quantity !== 0 ? oldData[item.productId].value / oldData[item.productId].quantity : 0;
                }
            });
            
            const dataToSend = { inputData: oldData };
            console.log(dataToSend);
    
            axios
            .put(`${BASE_API_URL}/storagechecks`, dataToSend)
            .then(() => {
                // navigate('/inputdocuments');
            })
            .catch((error) => {
                console.log(error);
            })
    
            axios
            .put(`${BASE_API_URL}/foreigninputdocuments/${id}`, data)
            .then(() => {
                navigate('/foreigninputdocuments');
            })
            .catch((error) => {
                console.log(error);
            })
        }


    };  

    const formik = useFormik({
        initialValues: newForeignInputDocumentInitalValues(inputData),
        validationSchema: createForeignInputDocumentValidation,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if(inputData.inputData) {
            const firstObject = Object.values(inputData?.inputData)[0];
            console.log(firstObject);
            const rate = firstObject.priceInRsd / firstObject.euroPrice;
            setRates(rate);
        }
    }, [inputData]);

    const handleInputChange = (productId, title, field, value) => {
        const parsedValue = parseFloat(value) || 0;
        const { productId: idProd } = productsData.find((item) => item.id === productId);
      
        formik.setFieldValue(`inputData.${productId}.${field}`, parsedValue);
        formik.setFieldValue(`inputData.${productId}.productId`, idProd);
        formik.setFieldValue(`inputData.${productId}.title`, title);

        const euroPrice = field === 'euroPrice' ? parsedValue : parseFloat(formik?.values?.inputData[productId]?.euroPrice) || 0;
        const priceInRsd = +(euroPrice * rates).toFixed(2);
        const load = field === 'load' ? parsedValue : parseFloat(formik?.values?.inputData[productId]?.load) || 0;
        const netoPrice = +(priceInRsd + load).toFixed(2);
        const { tax } = productsData.find((item) => item.id === productId);
        const priceWithTax = +(netoPrice + ((tax / 100) * netoPrice)).toFixed(2);
        console.log(euroPrice, priceInRsd, load, netoPrice, tax, priceWithTax);

        formik.setFieldValue(`inputData.${productId}.netoPrice`, netoPrice);
        formik.setFieldValue(`inputData.${productId}.priceWithTax`, priceWithTax);
        formik.setFieldValue(`inputData.${productId}.priceInRsd`, priceInRsd);
    }

    const handleCancelButton = () => {
        if(Object.keys(formik.touched).length > 0) {
            if(window.confirm('Promene koje ste napravili nece biti sacuvane. Da li ste sigurni?')) {
                navigate('/foreigninputdocuments');
            }
        } else {
            navigate('/foreigninputdocuments');
        }
    };  

    return (
        <>
            <NewInputDocumentContainer component="form" onSubmit={formik.handleSubmit}>
                <NewInputDocumentHeader>
                    <NewInputDocumentTitle sx={{fontSize: `${!isTablet ? '34px' : '28px'}`}}>Izmeni inostrani ulazni dokument</NewInputDocumentTitle>
                    <ButtonContainer>
                            <PrimaryButton text='Sacuvaj' save type='submit' />
                            <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                    </ButtonContainer>
                </NewInputDocumentHeader>

                <DropdownField label='Izaberi firmu' items={companiesData} 
                                        id="company"
                                        name="company"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company} 
                                        error={formik.touched.company && formik.errors.company}
                                        helperText={formik.touched.company && formik.errors.company}
                               />
                <DropdownField label='Izaberi magacin' items={storagesData} 
                                        id="storage"
                                        name="storage"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.storage} 
                                        error={formik.touched.storage && formik.errors.storage}
                                        helperText={formik.touched.storage && formik.errors.storage}
                                />
                <DropdownField label='Izaberi status dokumenta' items={documentStatusConstants} 
                                        id="status"
                                        name="status"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.status} 
                                        error={formik.touched.status && formik.errors.status}
                                        helperText={formik.touched.status && formik.errors.status}
                               />    
                <NewInputDocumentCommentsContainer>
                    <TextAreaField placeholder='Komentar (opsti)' 
                                       value={formik.values.basicComment} 
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='basicComment' name='basicComment' 
                                        error={formik.touched.basicComment && formik.errors.basicComment}
                                        helperText={formik.touched.basicComment && formik.errors.basicComment}
                                    />
                    <TextAreaField placeholder='Komentar (magacin)' 
                                       value={formik.values.storageComment} 
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='storageComment' name='storageComment' 
                                        error={formik.touched.storageComment && formik.errors.storageComment}
                                        helperText={formik.touched.storageComment && formik.errors.storageComment}
                                    />
                </NewInputDocumentCommentsContainer>
                <NewInputDocumentPageActionContainer>
                    <InputField noMarginTop label='Pretrazi proizvode...' type='text' onChange={(e) => setSearchValue(e.target.value)} />
                    <DropdownField label='Filter kategorija' items={productCategories} 
                                        id="category"
                                        name="category"          
                                        onChange={(e) => setFilterValue(e.target.value)}
                                        value={filterValue}
                    /> 
                    <DropdownField label='Filter podkategorija' items={subcategories} 
                                        id="subcategory"
                                        name="subcategory"          
                                        onChange={(e) => setFilterValue(e.target.value)}
                                        value={filterValue}
                    /> 
                    <PrimaryButton text='Ponisti' onClick={() => setFilterValue('')} />
                    <CourseField>
                    <InputField noMarginTop type='number' value={rates} onChange={(e) => setRates(e.target.value)} />
                    </CourseField>
                </NewInputDocumentPageActionContainer>
                {!isLoaded ? <Loader /> : productsData
                                                      .sort((a, b) => a.category.localeCompare(b.category))
                                                      .filter((item) => String(item.productId).toLowerCase().includes(searchValue.toLowerCase()) || item.title.toLowerCase().includes(searchValue.toLowerCase()))
                                                      .filter((item) => filterValue === '' || item.category === filterValue || item.subcategory === filterValue)
                                                      .map((item) => {
                      const productId = item.id;
                      const isCollapsed = collapsedItems[productId];

                    return (
                        <>
                            <ProductItemContainer key={productId}>
                                <ProductInfoContainer>{item.productId}</ProductInfoContainer>
                                <ProductInfoContainer>{item.title}</ProductInfoContainer>
                                    <DropdownIconContainer onClick={() =>
                                                            setCollapsedItems((prevCollapsed) => ({
                                                                ...prevCollapsed,
                                                                [productId]: !prevCollapsed[productId],
                                                            }))
                                                            } 
                                    >
                                    <DropdownIcon
                                        isCollapsed={!isCollapsed}
                                    />
                                    </DropdownIconContainer>
                                <InputField label='Kolicina' type='number' 
                                           name={`inputData.${productId}.quantity`}
                                           onChange={(e) => handleInputChange(item.id, item.title, 'quantity', e.target.value)}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.inputData[productId]?.quantity}
                                />
                                <InputField label='Cena u evrima' type='number' 
                                           name={`inputData.${productId}.euroPrice`}
                                           onChange={(e) => handleInputChange(item.id, item.title, 'euroPrice', e.target.value)}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.inputData[productId]?.euroPrice} 
                                />
                                <InputField label='Cena u dinarima' type='number' 
                                           defaultValue={0}
                                           name={`inputData.${productId}.priceInRsd`}
                                           inputProps={{ readOnly: true,}} value={formik.values.inputData[productId]?.priceInRsd}
                                />
                                <InputField label='Opterecenje po art/kom' type='number' 
                                           name={`inputData.${productId}.load`}
                                           onChange={(e) => handleInputChange(item.id, item.title, 'load', e.target.value)}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.inputData[productId]?.load} 
                                />
                                <InputField label='Neto cena' type='number' 
                                          defaultValue={0}
                                          name={`inputData.${productId}.netoPrice`}
                                          inputProps={{ readOnly: true,}} value={formik.values.inputData[productId]?.netoPrice}
                                />
                                <InputField label='Cena sa PDV-om' type='number' 
                                            defaultValue={0}
                                            name={`inputData.${productId}.priceWithTax`}
                                            inputProps={{ readOnly: true,}} value={formik.values.inputData[productId]?.priceWithTax}
                                />
                            </ProductItemContainer>
                            {isCollapsed && (
                                <CollapsibleFields productId={productId} input={inputDocumentsData} foreignInput={foreignInputDocumentsData} isCollapsed={isCollapsed} />
                            )}
                        </>

                    )
                })}
            </NewInputDocumentContainer>
        </>
    )
};

export default EditForeignInputDocumentPage;