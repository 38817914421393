import styled from "styled-components";
import { ReactComponent as IconPlus } from "../../../assets/plus-circled.svg";
import { ReactComponent as IconMinus } from "../../../assets/minus-cirlce.svg";
import { Typography, Box } from "@mui/material";

export const DynamicInputFieldsContainer = styled(Box)`
    margin-top: 12px;
`;

export const InputFieldContainer = styled(Box)`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const Label = styled(Typography)``;

export const PlusIcon = styled(IconPlus)`
    width: 25px;
    height: 25px;
    margin: 5px;
    cursor: pointer;
`;
export const MinusIcon = styled(IconMinus)`
    cursor: pointer;
`;