import React, { useState, useEffect } from "react";
import BASE_API_URL from "../../../constants/apiConstants";
import { InputDocumentDetailsCommentsContainer, InputDocumentDetailsContainer, InputDocumentDetailsData, InputDocumentDetailsInfoContainer, InputDocumentDetailsItem, InputDocumentDetailsTitle } from "../../InputDocumentsPage/InputDocumentDetailPage/InputDocumentDetails.styled";
import { useParams } from "react-router-dom";
import BasicTable from "../../../components/Table/Table";
import foreignInputDocumentDetailsTableConstants from "../../../constants/tableConstants/foreignInputDocumentDetailsTableConstants";

const ForeignInputDocumentDetailsPage = () => {
    const [data, setData] = useState({});
    const [array, setArray] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchForeignInputDocument = async () => {
            const res = await fetch(`${BASE_API_URL}/foreigninputdocuments/${id}`);
            const data = await res.json();
            if(data.success === false) {
                console.log(data.message);
                return;
            }

            setData(data);
            const arrayData = Object.values(data?.inputData);
            setArray(arrayData);
        } 
        fetchForeignInputDocument();
    }, [id]);

    return (
        <InputDocumentDetailsContainer>
            <InputDocumentDetailsTitle variant="h4">Pregled inostranog ulaznog dokumenta</InputDocumentDetailsTitle>
            <InputDocumentDetailsInfoContainer>
                <InputDocumentDetailsItem variant="h5">{data?.company}</InputDocumentDetailsItem>
                <InputDocumentDetailsItem variant="h5">{data?.storage}</InputDocumentDetailsItem>
                <InputDocumentDetailsCommentsContainer>
                    <InputDocumentDetailsItem>Status</InputDocumentDetailsItem>
                    <InputDocumentDetailsItem sx={{marginBottom: '10px'}} variant="h6">{data?.status}</InputDocumentDetailsItem>
                    <InputDocumentDetailsItem>Komentar (opsti)</InputDocumentDetailsItem>
                    <InputDocumentDetailsItem variant="h6">{data?.basicComment}</InputDocumentDetailsItem>
                    <InputDocumentDetailsItem>Komentar (magacin)</InputDocumentDetailsItem>
                    <InputDocumentDetailsItem variant="h6">{data?.storageComment}</InputDocumentDetailsItem>
                </InputDocumentDetailsCommentsContainer>
                <InputDocumentDetailsData>
                    <BasicTable data={array} tableHeader={foreignInputDocumentDetailsTableConstants} tableBody={foreignInputDocumentDetailsTableConstants} />
                </InputDocumentDetailsData>
            </InputDocumentDetailsInfoContainer>
        </InputDocumentDetailsContainer>
    )
};

export default ForeignInputDocumentDetailsPage;