import React, { useState, useEffect } from "react";
import axios from 'axios';
import { ButtonContainer, NewInputDocumentContainer, NewInputDocumentHeader, NewInputDocumentPageActionContainer, NewInputDocumentTitle, ProductInfoContainer, ProductItemContainer } from "../InputDocumentsPage/NewInputDocumentPage/NewInputDocumentPage.styled";
import PrimaryButton from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import InputField from "../../components/InputFields/InputField";
import { useNavigate } from "react-router-dom";

const LevelingPrices = () => {
    const [productsData, setProductsData] = useState([]);
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [inputData, setInputData] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://api.ivexkd.rs/products').then((res) => {
            console.log(res);
            setProductsData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
        axios.get('https://api.ivexkd.rs/levelingprices').then((res) => {
            console.log(res);
            setData(res.data);
        }).catch((error) => {
            console.log(error);
        })
    },[])

    const handleInputChange = (productId, field, value) => {
        setInputData((prevData) => {
      
          return {
            ...prevData,
            [productId]: {
              ...prevData[productId],
            //   [field]: parsedValue,
              vpPrice: value,
            },
          };
        });
      };

    const handleCancelButton = () => {
        navigate(-1);
    }; 

    console.log(data);

    const handleSubmit = () => {
        const oldData = data.inputData;
        const newData = inputData;
        Object.keys(newData).forEach((productId) => {
            if (oldData[productId]) {
              oldData[productId].vpPrice = +newData[productId].vpPrice;
            }
        });

        console.log('Updated oldData:', oldData);

        const dataToSend = {inputData: oldData};

        console.log(dataToSend);

        axios
        .put('https://api.ivexkd.rs/levelingprices', dataToSend)
        .then(() => {
            navigate(-1);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <NewInputDocumentContainer>
            <NewInputDocumentHeader>
            <NewInputDocumentTitle variant="h4">Nivelacija VP cena</NewInputDocumentTitle>
            <ButtonContainer>
                    <PrimaryButton text='Sacuvaj' save type='submit' onClick={handleSubmit} />
                    <PrimaryButton text='Odustani' onClick={handleCancelButton} />
            </ButtonContainer>
            </NewInputDocumentHeader>
            <NewInputDocumentPageActionContainer>
                    <InputField noMarginTop label='Pretrazi proizvode...' type='text' onChange={(e) => setSearchValue(e.target.value)} />
                </NewInputDocumentPageActionContainer>
            {!isLoaded ? <Loader /> : productsData.filter((item) => String(item.productId).toLowerCase().includes(searchValue.toLowerCase()) || item.title.toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => {

                    return (
                        <>
                            <ProductItemContainer key={index}>
                                <ProductInfoContainer>{item.productId}</ProductInfoContainer>
                                <ProductInfoContainer>{item.title}</ProductInfoContainer>
                                <ProductInfoContainer>{item.barcode}</ProductInfoContainer>
                                <ProductInfoContainer>{data && data.inputData && data?.inputData[item.productId]?.vpPrice}</ProductInfoContainer>

                                <InputField label='VP cena' type='number' onChange={(e) => handleInputChange(item.productId, 'vpPrice', e.target.value)} />
                            </ProductItemContainer>
                        </>

                    )
                })}
        </NewInputDocumentContainer>
    );
};

export default LevelingPrices;