import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const CollapsibleFieldsContainer = styled(Box)`
    display: flex;
    gap: 80px;
    justify-content: center;
    margin: 10px;

    @media (min-width: 768px) {
        margin-bottom: 30px;
    }
`;

export const CollapsibleFieldsItemContainer = styled(Box)``;

export const CollapsibleFieldsItem = styled(Typography)``;