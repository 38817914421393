// import ProductActionRenderer from "../../components/ActionRenderers/ProductsActionRenderer/ProductActionRenderer";

export default [
    {
       id: 0,
       body: `title`,
       columnTitle: 'Naziv magacina'
   },
    {
       id: 1,
       body: `isActive`,
       columnTitle:  'Aktivan'
   },
    {
       id: 2,
       body: `description`,
       columnTitle: 'Opis'
   },
   // {
   //     id: 12,
   //     body: <ProductActionRenderer />,
   //     columnTitle: 'Izmeni'
   // },
]