import React, {useState, useEffect} from "react";
import axios from 'axios';
import BASE_API_URL from "../../constants/apiConstants";
import { StoragePageHeader, StoragePageTitle, StoragesPageContainer } from "./StoragesPage.styled";
import BasicTable from "../../components/Table/Table";
import storagesTableConstants from "../../constants/tableConstants/storagesTableConstants";
import PrimaryButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const StoragesPage = () => {
    const [data, setData] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_API_URL}/storages`).then((res) => {
            console.log(res);
            setData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
    }, [isDataChanged])

    const handleClick = () => {
        navigate('/storages/new');
    }

    const onDeletedSuccess = (value) => {
        setIsDataChanged(value);
    }

    console.log(isDataChanged);

    return (
        <StoragesPageContainer>
            <StoragePageHeader>
                <StoragePageTitle variant="h4">Magacini</StoragePageTitle>
                <PrimaryButton text={'Dodaj novi'} onClick={handleClick} save />
            </StoragePageHeader>
            {!isLoaded ? <Loader /> : <BasicTable data={data} tableHeader={storagesTableConstants} tableBody={storagesTableConstants} onDeletedSuccess={(value) => onDeletedSuccess(value)} type='storages'/>}
        </StoragesPageContainer>
    );
};

export default StoragesPage;