import React from "react";
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ActionRendererContainer, IconContainer } from "./StoragesActionRenderer.styled";
import { useNavigate } from "react-router-dom";

const StorageActionRenderer = (props) => {
    const navigate = useNavigate();
    const handleEdit = () => {
        navigate(`/storages/edit/${props.id}`);
    }
    const handleOpenModal = () => {
        props.onOpenModal(true);
        props.onReturnId(props.id);
    }

    return (
        <>
        <ActionRendererContainer>
            <IconContainer onClick={handleEdit}>
                <EditIcon />
            </IconContainer>
            <IconContainer onClick={handleOpenModal}>
                <DeleteIcon />
            </IconContainer>
        </ActionRendererContainer>
        </>
    )
};

export default StorageActionRenderer;