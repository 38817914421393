import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const InputDocumentDetailsContainer = styled(Box)`
    padding: 50px;
`;

export const InputDocumentDetailsTitle = styled(Typography)``;

export const InputDocumentDetailsInfoContainer = styled(Box)`
    padding-top: 50px;
`;

export const InputDocumentDetailsItem = styled(Typography)``;

export const InputDocumentDetailsData = styled(Box)``;

export const InputDocumentDetailsCommentsContainer = styled(Box)`
    margin: 15px 0;
`;