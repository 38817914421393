export default (product = {}) => ({
    productId: product?.productId || '',
    title: product?.title || '',
    isActive: product?.isActive || false,
    category: product?.category || '',
    subcategory: product?.subcategory || '',
    transPackAmount: product?.transPackAmount || '',
    transPackUnit: product?.transPackUnit || '',
    basePackAmount: product?.basePackAmount || '',
    transportMass: product?.transportMass || '',
    barcode: product?.barcode || '',
    tax: product?.tax || '',
    price: product?.price || '',
    description: product?.description || '',
    color: product?.color || '',
  });