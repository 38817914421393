import React, {useState, useEffect} from "react";
import axios from 'axios';
import BASE_API_URL from "../../constants/apiConstants";
import { CompanyPageHeader, CompanyPageTitle, CompaniesPageContainer } from "./CompaniesPage.styled";
import BasicTable from "../../components/Table/Table";
import companiesTableConstants from "../../constants/tableConstants/companiesTableConstants";
import PrimaryButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const CompaniesPage = () => {
    const [data, setData] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_API_URL}/companies`).then((res) => {
            console.log(res);
            setData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
    }, [isDataChanged])

    const handleClick = () => {
        navigate('/companies/new');
    }

    const onDeletedSuccess = (value) => {
        setIsDataChanged(value);
    }

    console.log(isDataChanged);

    return (
        <CompaniesPageContainer>
            <CompanyPageHeader>
                <CompanyPageTitle variant="h4">Firme</CompanyPageTitle>
                <PrimaryButton text={'Dodaj novi'} onClick={handleClick} save />
            </CompanyPageHeader>
            {!isLoaded ? <Loader /> : <BasicTable data={data} tableHeader={companiesTableConstants} tableBody={companiesTableConstants} onDeletedSuccess={(value) => onDeletedSuccess(value)} type='companies' />}
        </CompaniesPageContainer>
    );
};

export default CompaniesPage;