import React, { useState } from 'react';
import { CollapsibleFieldsContainer, CollapsibleFieldsItem, CollapsibleFieldsItemContainer } from '../../../InputDocumentsPage/NewInputDocumentPage/CollapsibleFields/CollapsibleFields.styled';
import { formattedDate } from '../../../../util/helpers/dateHelpers';
import { Button, CollapsibleFieldContainer, CommissionConditions, RetailPriceContainer } from './CollapsibleFields.styled';
import PrimaryButton from '../../../../components/Button/Button';

const CollapsibleFields = (props) => {
  const [hideField, setHideField] = useState(true);
  console.log(props);
  const outputData = props?.outputData;
  const company = props?.company;
  let filteredOutputData;
  if (Array.isArray(outputData)) {
    filteredOutputData = outputData.filter((item) => item.inputData && item.inputData[props.productId] && item.company === company);
  } else {
    console.error("data is not an array");
  }
  console.log(filteredOutputData);

  // const concatedArrays = filteredInputData?.concat(filteredForeignInputData);
  const sortedArray = filteredOutputData && filteredOutputData?.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
  
    return dateB - dateA;
  }); 
  
  const netoPrice = props?.netoPrice || 0;
  const purchasePrice = props?.purchasePrice || 0;
  const marza = (netoPrice / purchasePrice);
  const priceWithTax = Number(props?.priceWithTax) || 0;

  console.log(marza, netoPrice);

  const buttonHandler = () => {
    setHideField(!hideField);
  }

  return (
    <CollapsibleFieldContainer>
      <CollapsibleFieldsContainer>
        {sortedArray !== undefined && sortedArray?.slice(0, 3).map((item, index) => {
          return (
                <CollapsibleFieldsItemContainer key={index}>
                    <CollapsibleFieldsItem>{item.company}</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>{item.storage}</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>{formattedDate(item.createdAt)}</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>Kolicina: {item.inputData[props.productId].quantity}</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>Osnovna cena: {item.inputData[props.productId].vpPrice}</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>Rabat: {item.inputData[props.productId].rabat}%</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>Neto cena: {item.inputData[props.productId].netoPrice.toFixed(2)}</CollapsibleFieldsItem>
                    <CollapsibleFieldsItem>Komentar: {item.inputData[props.productId].comment === 0 ? '' : item.inputData[props.productId].comment}</CollapsibleFieldsItem>
                </CollapsibleFieldsItemContainer>
          )
        })}
        </CollapsibleFieldsContainer>
        <CommissionConditions>
          <PrimaryButton onClick={buttonHandler} text='&nbsp;' />
          {hideField && 
          <>
            <CollapsibleFieldsItem>Marža VP moja: {marza.toFixed(2)}</CollapsibleFieldsItem>
            <CollapsibleFieldsItem>Nabavna cena: {purchasePrice}</CollapsibleFieldsItem>
            <CollapsibleFieldsItem>Prodajna cena: {netoPrice}</CollapsibleFieldsItem>
          </>
          }
        </CommissionConditions>
        <RetailPriceContainer>
          <CollapsibleFieldsItem>MP marža 20% {(priceWithTax + (20/100 * priceWithTax)).toFixed(2)}</CollapsibleFieldsItem>
          <CollapsibleFieldsItem>MP marža 25% {(priceWithTax + (25/100 * priceWithTax)).toFixed(2)}</CollapsibleFieldsItem>
          <CollapsibleFieldsItem>MP marža 30% {(priceWithTax + (30/100 * priceWithTax)).toFixed(2)}</CollapsibleFieldsItem>
          <CollapsibleFieldsItem>MP marža 35% {(priceWithTax + (35/100 * priceWithTax)).toFixed(2)}</CollapsibleFieldsItem>
        </RetailPriceContainer>
      </CollapsibleFieldContainer>
  );
};

export default CollapsibleFields;