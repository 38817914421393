import React from "react";
import { Backdrop } from "@mui/material";
// import { alpha } from "@mui/system";

const BackdropComponent = ({
  position = "fixed",
  isLoading,
  handleClose,
}) => (
  <Backdrop
    sx={{
      // 'fixed' takes whole page, 'absolute' takes whole space of the parent element which needs to have 'relative' position
      position,
      //   zIndex: ({ zIndex }) => zIndex.drawer + 1,
    //   zIndex: 140,
    }}
    open={isLoading}
    onClick={handleClose}
    style={{
      backgroundColor: "rgba(0,0,0,0.3)",
    }}
  >
    {/* <CircularProgress /> */}
  </Backdrop>
);


export default BackdropComponent;
