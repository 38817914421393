import React from 'react';
import { CollapsibleFieldsContainer, CollapsibleFieldsItem, CollapsibleFieldsItemContainer } from './CollapsibleFields.styled';
import { formattedDate } from '../../../../util/helpers/dateHelpers';

const CollapsibleFields = (props) => {
  const inputData = props?.input;
  const foreignInputData = props?.foreignInput;
  let filteredInputData, filteredForeignInputData;
  if (Array.isArray(inputData) && Array.isArray(foreignInputData)) {
    filteredInputData = inputData.filter((item) => item.inputData && item.inputData[props.productId]);
    filteredForeignInputData = foreignInputData.filter((item) => item.inputData && item.inputData[props.productId]);
  } else {
    console.error("data is not an array");
  }
  console.log(filteredInputData, filteredForeignInputData);
  const concatedArrays = filteredInputData?.concat(filteredForeignInputData);
  const sortedArray = concatedArrays && concatedArrays?.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
  
    return dateB - dateA;
  });  

  console.log(sortedArray);

  return (
    <CollapsibleFieldsContainer>
      {sortedArray !== undefined && sortedArray?.slice(0, 3).map((item, index) => {
        console.log(item.inputData[props.productId].basePrice);
        return (
              <CollapsibleFieldsItemContainer key={index}>
                  <CollapsibleFieldsItem>{item.company}</CollapsibleFieldsItem>
                  <CollapsibleFieldsItem>{item.storage}</CollapsibleFieldsItem>
                  <CollapsibleFieldsItem>{formattedDate(item.createdAt)}</CollapsibleFieldsItem>
                  <CollapsibleFieldsItem>Kolicina: {item.inputData[props.productId].quantity}</CollapsibleFieldsItem>
                  {item.inputData[props.productId].basePrice !== undefined && <CollapsibleFieldsItem>Osnovna cena: {item.inputData[props.productId].basePrice}</CollapsibleFieldsItem>}
                  {item.inputData[props.productId].rabat !== undefined && <CollapsibleFieldsItem>Rabat: {item.inputData[props.productId].rabat}%</CollapsibleFieldsItem>}
                  {item.inputData[props.productId].euroPrice && <CollapsibleFieldsItem>Cena u evrima: {item.inputData[props.productId].euroPrice}</CollapsibleFieldsItem>}
                  {item.inputData[props.productId].priceInRsd && <CollapsibleFieldsItem>Cena u dinarima: {item.inputData[props.productId].priceInRsd}</CollapsibleFieldsItem>}
                  <CollapsibleFieldsItem>Neto cena: {item.inputData[props.productId].netoPrice}</CollapsibleFieldsItem>
              </CollapsibleFieldsItemContainer>
        )
      })}
      </CollapsibleFieldsContainer>
  );
};

export default CollapsibleFields;