import React, {useState, useEffect} from "react";
import axios from 'axios';
import BASE_API_URL from "../../constants/apiConstants";
import { ForeignInputDocumentPageHeader, ForeignInputDocumentPageTitle, ForeignInputDocumentsPageContainer } from "./ForeignInputDocumentsPage.styled";
import BasicTable from "../../components/Table/Table";
import foreignInputDocumentsTableConstants from "../../constants/tableConstants/foreignInputDocumentsTableConstants";
import PrimaryButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const ForeignInputDocumentsPage = () => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_API_URL}/foreigninputdocuments`).then((res) => {
            console.log(res);
            setData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
    }, [])

    const handleClick = () => {
        navigate('/foreigninputdocuments/new');
    }


    return (
        <ForeignInputDocumentsPageContainer>
            <ForeignInputDocumentPageHeader>
                <ForeignInputDocumentPageTitle variant="h4">Inostrani ulazni dokumenti</ForeignInputDocumentPageTitle>
                <PrimaryButton text={'Dodaj novi'} onClick={handleClick} save />
            </ForeignInputDocumentPageHeader>
            {!isLoaded ? <Loader /> : <BasicTable data={data} tableHeader={foreignInputDocumentsTableConstants} tableBody={foreignInputDocumentsTableConstants} type='foreignInputDocuments'/>}
        </ForeignInputDocumentsPageContainer>
    );
};

export default ForeignInputDocumentsPage;