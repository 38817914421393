// import ProductActionRenderer from "../../components/ActionRenderers/ProductsActionRenderer/ProductActionRenderer";

export default [
     {
        id: 0,
        body: `productId`,
        columnTitle: 'Sifra proizvoda'
    },
     {
        id: 1,
        body: `isActive`,
        columnTitle:  'Aktivan'
    },
     {
        id: 2,
        body: `title`,
        columnTitle: 'Naziv proizvoda'
    },
     {
        id: 3,
        body: `transPackAmount`,
        columnTitle: 'Kol. trans. pakovanja'
    },
     {
        id: 4,
        body: `transPackUnit`,
        columnTitle: 'J. M. trans. pakovanja'
    },
     {
        id: 5,
        body: `basePackAmount`,
        columnTitle: 'Kol. osnovnog pakovanja'
    },
     {
        id: 6,
        body: `transportMass`,
        columnTitle: 'Transportna masa'
    },
     {
        id: 7,
        body: `barcode`,
        columnTitle: 'Barkod'
    },
     {
        id: 8,
        body: `tax`,
        columnTitle: 'Stopa PDV u %'
    },
     {
        id: 9,
        body: `price`,
        columnTitle: 'Cena'
    },
     {
        id: 10,
        body: `description`,
        columnTitle: 'Opis'
    },
     {
        id: 11,
        body: `color`,
        columnTitle: 'Boja'
    },
    // {
    //     id: 12,
    //     body: <ProductActionRenderer />,
    //     columnTitle: 'Izmeni'
    // },
]