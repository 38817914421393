import React from "react";
import { ReactComponent as EyeIcon } from "../../../assets/eye.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";
import { ActionRendererContainer, IconContainer } from "../ProductsActionRenderer/ProductActionRenderer.styled";
import { useNavigate } from "react-router-dom";

const ForeignInputDocumentActionRenderer = (props) => {
    const navigate = useNavigate();
    const handleCheckInputDocument = () => {
        navigate(`/foreigninputdocuments/${props.id}`);
    }
    const handleEdit = () => {
        navigate(`/foreigninputdocuments/edit/${props.id}`);
    }
    const handleOpenModal = () => {
        props.onOpenModal(true);
        props.onReturnId(props.id);
    }

    return (
        <>
        <ActionRendererContainer>
            <IconContainer onClick={handleCheckInputDocument}>
                <EyeIcon />
            </IconContainer>
            {props.status !== "Zavrsen" && <IconContainer onClick={handleEdit}>
                <EditIcon />
            </IconContainer>}
            <IconContainer onClick={handleOpenModal}>
                <DeleteIcon />
            </IconContainer>
        </ActionRendererContainer>
        </>
    )
};

export default ForeignInputDocumentActionRenderer;