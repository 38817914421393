import axios from 'axios';

const fetchData = async (url, setDataFunction, setLoadingFunction = null) => {
  try {
    const response = await axios.get(url);
    setDataFunction(response.data);
    if(setLoadingFunction) {
      setLoadingFunction(true);  // Ensure loading is set to true
    }
  } catch (error) {
    console.error(error);
    if(setLoadingFunction) {
      setLoadingFunction(false);  // Set loading to false in case of an error
    }
  }
};

export default fetchData;