import { Box } from "@mui/material";
import styled from "styled-components";

export const NewStorageForm = styled(Box)``;

export const NewStorageFormContainer = styled(Box)`
    display: flex;
    gap: 50px;
`;

export const NewStorageFormDiv = styled(Box)``;

export const ButtonContainer = styled(Box)`
    display: flex;
    gap: 20px;
    margin: 18px;
`;