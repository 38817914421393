// import ProductActionRenderer from "../../components/ActionRenderers/ProductsActionRenderer/ProductActionRenderer";

export default [
    {
       id: 0,
       body: `title`,
       columnTitle: 'Naziv kategorije'
   },
//     {
//        id: 1,
//        body: `subcategories`,
//        columnTitle:  'Podkategorije'
//    },
]