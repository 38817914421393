import { Box } from "@mui/material";
import styled from "styled-components";


export const SetDeleteModalContainer = styled(Box)`
  padding: 32px;
  position: absolute;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: calc(50% - 128px);
  left: calc(50% - 153px);
`;

export const CloseModalContainer = styled(Box)`
    position: absolute;
    right: 20px;
    top: 20px;
`;

export const DeleteModalDescription = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */
  letter-spacing: -0.02em;
  text-align: left;
  font-feature-settings: "salt" on;
`;

export const ModalTitle = styled(Box)`
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-feature-settings: "salt" on;
  text-align: left;
  color: #000;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 20px;
  justify-content: center;
`;
export const ProductInfo = styled(Box)`
display: flex;
justify-content: center;
text-align: center;
font-size: 20px;
`;