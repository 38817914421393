import React, { useState, useEffect } from "react";
import {
  DeleteModalDescription,
  SetDeleteModalContainer,
  ButtonsContainer,
  ModalTitle,
  CloseModalContainer,
  ProductInfo,
} from "./DeleteProductModal.styled";
import BackdropComponent from "../../MUI/BackdropComponent";
import PrimaryButton from "../../Button/Button";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import axios from "axios";


const DeleteProductModal = (props) => {
    const [fetchedProduct, setFetchedProduct] = useState([]);
console.log(props);

useEffect(() => {
    const fetchProduct = async () => {
      const res = await fetch(`http://localhost:5000/products/${props.id}`);
      const data = await res.json();
      if (data && data.success === false) {
        console.log(data.message);
        return;
      }

      return setFetchedProduct(data);

    };

    fetchProduct();
  }, [props.id]);

  const handleCloseModal = () => {
    props.onOpenModal(false);
  };

  const handleDeleteProduct = () => {
    axios.delete(`http://localhost:5000/products/${props.id}`)
    .then(() => {
        props.onOpenModal(false);
        props.onDeletedSuccess(true);
    })
    .catch((error) => {
        console.log(error);
    })
  };

  return (
    <>
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      <SetDeleteModalContainer>
        <CloseModalContainer>
            <CloseIcon onClick={handleCloseModal} />
        </CloseModalContainer>
        {/* <CloseButton onClick={handleCloseModal} /> */}
        <ModalTitle>{props?.title}</ModalTitle>
        <DeleteModalDescription>
          Da li ste zelite da obrisete oznaceni artikal?
        </DeleteModalDescription>
        <ProductInfo>
            {fetchedProduct.productId}
            <br />
            {fetchedProduct.title}
        </ProductInfo>
        <ButtonsContainer>
            <PrimaryButton text='Obrisi' save onClick={handleDeleteProduct} />
            <PrimaryButton text='Odustani' onClick={handleCloseModal} />
        </ButtonsContainer>
      </SetDeleteModalContainer>
    </>
  );
};

export default DeleteProductModal;
