import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function PrimaryButton(props) {
  return (
    <Stack spacing={1} direction="row">
      <Button variant={props.save ? "contained" : "outlined"} type={props.type} onClick={props.onClick}>{props.text}</Button>
    </Stack>
  );
}