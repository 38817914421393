import React, { useState, useEffect, useRef } from "react";
import { DynamicInputFieldsContainer, InputFieldContainer, Label, MinusIcon, PlusIcon } from "./DynamicInputFields.styled";
// import InputField from "../InputField";
import TextAreaField from "../TextAreaField";
import InputField from "../InputField";

const DynamicInputFields = (props) => {
  const [inputFields, setInputFields] = useState([]);
  const isInitialRender = useRef(true);
  // let idCounter = props.existingData.length > 0 ? props.existingData.length : 0;
  // console.log("props.existingData:", props.existingData);
  // console.log(inputFields[inputFields.length - 1]?.id + 1);
  // console.log(inputFields);

  useEffect(() => {
    if (props.existingData && props.existingData.length > 0) {
      setInputFields(props.existingData);
    }
  }, [props.existingData]);

  useEffect(() => {
    if (!isInitialRender.current) {
      // Call the parent component's function when inputFields change
      props.onSetInputFields(inputFields);
    } else {
      isInitialRender.current = false;
    }
  }, [inputFields, props]);

  const handleAddField = () => {
    const newInputField = {
      id: inputFields[inputFields.length - 1]?.id + 1 || 0,
      value: "",
    };

    setInputFields([...inputFields, newInputField]);
  };

  const handleInputChange = (id, value) => {
    setInputFields((prevInputFields) =>
      prevInputFields.map((field) =>
        field.id === id ? { ...field, value } : field
      )
    );
  };

  const handleRemoveField = (id) => {
    setInputFields((prevInputFields) =>
      prevInputFields.filter((field) => field.id !== id)
    );
  };

  return (
    <DynamicInputFieldsContainer>
      <Label>{props.label}</Label>
      {inputFields.map((field) => (
        <div key={field.id}>
          {props.type === 'textArea' ? (
          <InputFieldContainer> 
            <TextAreaField
              type="text"
              value={field.value}
              onChange={(e) => handleInputChange(field.id, e.target.value)}
              placeholder={props.placeholder}
            /> 
            <MinusIcon onClick={() => handleRemoveField(field.id)} /> 
          </InputFieldContainer>) : (
            <InputFieldContainer>
              <InputField type='text' value={field.value} onChange={(e) => handleInputChange(field.id, e.target.value)} /> 
              <MinusIcon onClick={() => handleRemoveField(field.id)} />
            </InputFieldContainer>
          )}
        </div>
      ))}
      <PlusIcon onClick={handleAddField} />
    </DynamicInputFieldsContainer>
  );
};

export default DynamicInputFields;