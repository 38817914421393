import React, {useState, useEffect} from "react";
import axios from 'axios';
import BASE_API_URL from "../../constants/apiConstants";
import { InputDocumentPageHeader, InputDocumentPageTitle, InputDocumentsPageContainer } from "./InputDocumentsPage.styled";
import BasicTable from "../../components/Table/Table";
import inputDocumentsTableConstants from "../../constants/tableConstants/inputDocumentsTableConstants";
import PrimaryButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const InputDocumentsPage = () => {
    const [data, setData] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${BASE_API_URL}/inputdocuments`).then((res) => {
            console.log(res);
            setData(res.data);
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error);
            setIsLoaded(false);
        })
    }, [isDataChanged])

    const handleClick = () => {
        navigate('/inputdocuments/new');
    }

    const onDeletedSuccess = (value) => {
        setIsDataChanged(value);
    }

    console.log(isDataChanged);

    return (
        <InputDocumentsPageContainer>
            <InputDocumentPageHeader>
                <InputDocumentPageTitle variant="h4">Ulazni dokumenti</InputDocumentPageTitle>
                <PrimaryButton text={'Dodaj novi'} onClick={handleClick} save />
            </InputDocumentPageHeader>
            {!isLoaded ? <Loader /> : <BasicTable data={data} tableHeader={inputDocumentsTableConstants} tableBody={inputDocumentsTableConstants} onDeletedSuccess={(value) => onDeletedSuccess(value)} type='inputDocuments'/>}
        </InputDocumentsPageContainer>
    );
};

export default InputDocumentsPage;