import React from "react";
import axios from "axios";
import BASE_API_URL from "../../../constants/apiConstants";
import { ProductPageTitle, ProductsPageContainer } from "../../Products/ProductsPage.styled";
import InputField from "../../../components/InputFields/InputField";
import { ButtonContainer, NewStorageForm, NewStorageFormContainer, NewStorageFormDiv } from "./NewStoragePage.styled";
import PrimaryButton from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import CheckboxField from "../../../components/InputFields/CheckboxField";
import TextAreaField from "../../../components/InputFields/TextAreaField";
import { useFormik } from 'formik';
import newStorageInitialValues from "../../../initialValues/newStorageInitialValues";
import createStorageValidation from "../../../validations/createStorageValidation";

const NewStoragePage = () => {
    const navigate = useNavigate();

    const handleSubmit = (values) => {
        const data = {
            title: values.title,
            isActive: values.isActive === '' ? false : values.isActive,
            description: values.description,
        }

        axios
        .post(`${BASE_API_URL}/storages`, data)
        .then(() => {
            navigate('/storages');
        })
        .catch((error) => {
            console.log(error);
        })
    };

    const formik = useFormik({
        initialValues: newStorageInitialValues(),
        validationSchema: createStorageValidation,
        onSubmit: handleSubmit,
      });

    const handleCancelButton = () => {
        navigate(-1);
    };

    return (
        <ProductsPageContainer component="form" onSubmit={formik.handleSubmit}>
           <ProductPageTitle variant="h4">Novi magacin</ProductPageTitle>
           <NewStorageForm>
                <NewStorageFormContainer>
                    <NewStorageFormDiv>
                        <InputField label='Naziv magacina' type='text'           
                                        id="title"
                                        name="title"          
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title} 
                                        error={formik.touched.title && formik.errors.title}
                                        helperText={formik.touched.title && formik.errors.title} />
                        <CheckboxField label='Aktivan' value={formik.values.isActive} 
                                       onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                        onBlur={formik.handleBlur}
                                        id='isActive'
                                        name='isActive' 
                                        error={formik.touched.isActive && formik.errors.isActive}
                                        helperText={formik.touched.isActive && formik.errors.isActive} /> 
                        <TextAreaField placeholder='Opis' value={formik.values.description}                                         
                                       onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id='description' name='description' 
                                        error={formik.touched.description && formik.errors.description}
                                        helperText={formik.touched.description && formik.errors.description} />
                    </NewStorageFormDiv>
                </NewStorageFormContainer>
                <ButtonContainer>
                    <PrimaryButton text='Sacuvaj' save type='submit'/>
                    <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                </ButtonContainer>
           </NewStorageForm>


        </ProductsPageContainer>
    ) 
};

export default NewStoragePage;