// Format the date as a string in a desired format for Serbian 
export const formattedDate = (originalDateString) => {
    const date = new Date(originalDateString);

    const formatedDate = date.toLocaleDateString('sr-RS', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'UTC', // Assuming the original string is in UTC
      })

    return formatedDate;  
};