import React, { useState } from "react";
import axios from "axios";
import BASE_API_URL from "../../../constants/apiConstants";
import { ProductPageTitle, ProductsPageContainer } from "../../Products/ProductsPage.styled";
import InputField from "../../../components/InputFields/InputField";
import PrimaryButton from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { NewStorageForm, NewStorageFormContainer, NewStorageFormDiv } from "../../Storages/NewStoragePage/NewStoragePage.styled";
import { ButtonContainer } from "../../Companies/NewCompanyPage/NewCompanyPage.styled";
import DynamicInputFields from "../../../components/InputFields/DynamicInputFields/DynamicInputFields";
import { useFormik } from 'formik';
import newProductCategoryInitialValues from "../../../initialValues/newProductCategoryInitialValues";
import createProductCategoryValidation from "../../../validations/createProductCategoryValidation";

const NewCategoryPage = () => {
    const [subcategories, setSubcategories] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = (values) => {
        const data = {
            title: values.title,
            subcategories: subcategories,
        }

        console.log(data);

        axios
        .post(`${BASE_API_URL}/categories`, data)
        .then(() => {
            navigate('/categories');
        })
        .catch((error) => {
            console.log(error);
        })

    }

    const onSetInputFields =(value) => {
        setSubcategories(value);
    }

    const onSetParsedArray =(value) => {
        setSubcategories(value);
    }

    const formik = useFormik({
        initialValues: newProductCategoryInitialValues(),
        validationSchema: createProductCategoryValidation,
        onSubmit: handleSubmit,
      });


    const handleCancelButton = () => {
        navigate(-1);
    };
    return (
        <ProductsPageContainer component="form" onSubmit={formik.handleSubmit}>
           <ProductPageTitle variant="h4">Nova kategorija</ProductPageTitle>
           <NewStorageForm>
                <NewStorageFormContainer>
                    <NewStorageFormDiv>
                        <InputField label='Naziv kategorije' type='text'
                                    id="title"
                                    name="title"          
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title} 
                                    error={formik.touched.title && formik.errors.title}
                                    helperText={formik.touched.title && formik.errors.title} />
                        <DynamicInputFields label='Podkategorije' existingData={subcategories} onSetInputFields={onSetInputFields} onSetParsedArray={onSetParsedArray} />
                    </NewStorageFormDiv>
                </NewStorageFormContainer>
                <ButtonContainer>
                    <PrimaryButton text='Sacuvaj' save type='submit' />
                    <PrimaryButton text='Odustani' onClick={handleCancelButton} />
                </ButtonContainer>
           </NewStorageForm>


        </ProductsPageContainer>
    ) 
};

export default NewCategoryPage;