export default (company = {}) => ({
    title: company?.title || '',
    isActive: company?.isActive || false,
    isOnSEF: company?.isOnSEF || false,
    idNumber: company?.idNumber || '',
    pib: company?.pib || '',
    address: company?.address || '',
    city: company?.city || '',
    phone: company?.phone || '',
    phoneMob: company?.phoneMob || '',
    fax: company?.fax || '',
    email: company?.email || '',
    description: company?.description || '',
  });